// src/utils/auth.js
export const AUTH_STORAGE_KEY = 'docusign_auth';
export const USER_STORAGE_KEY = 'docusign_user';

export const authStorage = {
  get: () => {
    try {
      const authData = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY));
      return authData || null;
    } catch {
      return null;
    }
  },

  set: (authData) => {
    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authData));
  },

  clear: () => {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    localStorage.removeItem(USER_STORAGE_KEY);
  },

  getAccessToken: () => {
    const authData = authStorage.get();
    return authData?.accessToken || null;
  },

  getRefreshToken: () => {
    const authData = authStorage.get();
    return authData?.refreshToken || null;
  },

  // Helper to check if token is expired or about to expire (within 1 minute)
  isTokenExpired: () => {
    const authData = authStorage.get();
    if (!authData?.expiresAt) return true;
    
    // Check if token expires in less than 1 minute
    return new Date(authData.expiresAt).getTime() - 60000 < Date.now();
  },

  // User data management
  getUserData: () => {
    try {
      const userData = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
      return userData || null;
    } catch {
      return null;
    }
  },

  setUserData: (userData) => {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userData));
  },

  clearUserData: () => {
    localStorage.removeItem(USER_STORAGE_KEY);
  }
};