import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const SignatureConfirmationPopup = ({ isOpen, onClose, onConfirm, name }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: 'spring', damping: 25, stiffness: 500 }}
            className="bg-white dark:bg-darkbg-800 rounded-lg p-6 max-w-sm w-full m-4"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-semibold mb-4 text-grayteck-900 dark:text-grayteck-100">Confirm Signature</h2>
            <p className="text-grayteck-700 dark:text-grayteck-300 mb-6">
              Are you sure you want to sign this document as "{name}"? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-4 py-2 bg-grayteck-200 text-grayteck-800 rounded-md hover:bg-grayteck-300 focus:outline-none focus:ring-2 focus:ring-grayteck-400"
                onClick={onClose}
              >
                Cancel
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400"
                onClick={onConfirm}
              >
                Sign Document
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SignatureConfirmationPopup;