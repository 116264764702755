import React from 'react';

const TemplateSelector = ({ activeTemplate, onTemplateChange }) => {
  return (
    <div className="flex gap-2 mb-4">
      <button
        onClick={() => onTemplateChange('standard')}
        className={`px-4 py-2 rounded-md ${
          activeTemplate === 'standard' 
            ? 'bg-grayteck-600 text-white' 
            : 'bg-grayteck-200 text-grayteck-800'
        }`}
      >
        Standard Document
      </button>
      <button
        onClick={() => onTemplateChange('capabilities')}
        className={`px-4 py-2 rounded-md ${
          activeTemplate === 'capabilities' 
            ? 'bg-grayteck-600 text-white' 
            : 'bg-grayteck-200 text-grayteck-800'
        }`}
      >
        Capabilities Document
      </button>
      <button
        onClick={() => onTemplateChange('proposal')}
        className={`px-4 py-2 rounded-md ${
          activeTemplate === 'proposal' 
            ? 'bg-grayteck-600 text-white' 
            : 'bg-grayteck-200 text-grayteck-800'
        }`}
      >
        Project Proposal
      </button>
    </div>
  );
};

export default TemplateSelector;