import { createAxiosInstance } from './axiosInstance';


// const apiAxios = createAxiosInstance(`${process.env.REACT_APP_API_LOCAL_BASE_URL}/api`);
const apiAxios = createAxiosInstance(`${process.env.REACT_APP_API_PROD_BASE_URL}/api`);

// Add a request interceptor to include the token in all requests
apiAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Add a response interceptor to handle 401 errors
apiAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear the token and redirect to login
      localStorage.removeItem('access_token');
      window.dispatchEvent(new CustomEvent('unauthorized'));
    }
    return Promise.reject(error);
  }
);

export const getAllDocuments = async () => {
  try {
    const response = await apiAxios.get('/documents');
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getDocument = async (id) => {
  try {
    const response = await apiAxios.get(`/documents/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const createDocument = async (documentData) => {
  try {
    const response = await apiAxios.post('/documents', documentData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const updateDocument = async ({ id, ...documentData }) => {
    try {
    console.log('fetcher', id)
    console.log('fetcher', documentData)
    const response = await apiAxios.put(`/documents/${id}`, documentData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const deleteDocument = async (id) => {
  try {
    const response = await apiAxios.delete(`/documents/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};


export const publishDocument = async (id) => {
    try {
      const response = await apiAxios.post(`/documents/${id}/publish`);
      return response.data;
    } catch (error) {
        console.log(error)
      throw error.response ? error.response.data : error.message;
    }
  };



  export const getPublicDocument = async (id) => {
    try {
      const response = await apiAxios.get(`/documents/public/${id}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };


  export const getSharedDocument = async (token) => {
    try {
      const response = await apiAxios.get(`/documents/share/${token}`, {
        headers: {
            'Accept': 'application/json'  // Changed from text/html
        }
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };


//   export const signDocument = async (token, signerName) => {
//     try {
//       const response = await apiAxios.post(`/documents/sign/${token}`, {
//         signer_name: signerName,
//         signature_date: new Date().toISOString()
//       });
//       return response.data;
//     } catch (error) {
//       throw error.response ? error.response.data : error.message;
//     }
//   };


  // api_fethcers.js

export const signDocument = async (token, signatureData) => {
    try {
      const response = await apiAxios.post(`/documents/sign/${token}`, {
        signer_name: signatureData.signer_name,
        signature_img: signatureData.signature_img,
        signature_date: signatureData.signature_date
      });
      return response.data;
    } catch (error) {
      // Enhanced error handling
      if (error.response) {
        const errorMessage = error.response.data.detail || error.response.data.message;
        throw new Error(errorMessage);
      }
      throw new Error('Failed to connect to the server');
    }
  };


  export const downloadDocumentAsPDF = async (id) => {
    try {
      const response = await apiAxios.get(`/documents/download/${id}`, {
        responseType: 'blob',  // Ensure we handle binary data
      });
  
      // Create a link element, trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `document-${id}.pdf`); // Set the file name for download
      document.body.appendChild(link);
      link.click();
  
      // Clean up the URL object
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download PDF error:', error);
      throw error.response ? error.response.data : error.message;
    }
  };
  

  export const unpublishDocument = async (id) => {
    try {
      const response = await apiAxios.post(`/documents/${id}/unpublish`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };