import React, { useState } from 'react';
import { LinkIcon, CheckIcon, ClockIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const PublishedLinkSection = ({ document }) => {
  const [copied, setCopied] = useState(false);
  
  // Only render if document has a shareable link
  if (!document?.shareable_link) {
    return null;
  }

  const isLinkExpired = document.link_expires_at && new Date(document.link_expires_at) < new Date();
  
  const expiryDate = document.link_expires_at ? new Date(document.link_expires_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }) : null;

  // Generate frontend URL from backend URL
  const getFrontendUrl = () => {
    const token = document.shareable_link.split('/').pop();
    return `${window.location.origin}/share/${token}`;
  };

  const handleCopyLink = async () => {
    const frontendUrl = getFrontendUrl();
    try {
      await navigator.clipboard.writeText(frontendUrl);
      setCopied(true);
      toast.success('Link copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mt-6 mb-6 bg-white dark:bg-darkbg-700 rounded-lg border border-grayteck-200 dark:border-grayteck-600 p-4"
    >
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center">
          {isLinkExpired ? (
            <XCircleIcon className="h-5 w-5 text-red-500 mr-2" />
          ) : (
            <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
          )}
          <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
            Published Link
          </h3>
        </div>
        <span className={`text-sm font-medium px-2 py-1 rounded-full ${
          isLinkExpired 
            ? 'bg-red-100 text-red-600 dark:bg-red-900/30 dark:text-red-400' 
            : 'bg-green-100 text-green-600 dark:bg-green-900/30 dark:text-green-400'
        }`}>
          {isLinkExpired ? 'Expired' : 'Active'}
        </span>
      </div>
      
      <div className="flex flex-col space-y-3">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={getFrontendUrl()}
            readOnly
            className="flex-1 p-2 bg-grayteck-50 dark:bg-darkbg-800 border border-grayteck-200 dark:border-grayteck-600 rounded-md text-grayteck-800 dark:text-grayteck-200 text-sm"
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleCopyLink}
            className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center ${
              copied 
                ? 'bg-green-500 hover:bg-green-600 focus:ring-green-400' 
                : 'bg-grayteck-600 hover:bg-grayteck-700 focus:ring-grayteck-400'
            } text-white transition-colors duration-200`}
          >
            {copied ? (
              <>
                <CheckIcon className="h-5 w-5 mr-2" />
                Copied!
              </>
            ) : (
              <>
                <LinkIcon className="h-5 w-5 mr-2" />
                Copy Link
              </>
            )}
          </motion.button>
        </div>
        
        <div className="flex items-center text-sm text-grayteck-600 dark:text-grayteck-400">
          <ClockIcon className="h-4 w-4 mr-2" />
          <span>
            {isLinkExpired ? 'Expired on: ' : 'Expires on: '}
            {expiryDate}
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default PublishedLinkSection;