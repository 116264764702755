import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, FileText, Download, User } from 'lucide-react'; // Replace Home with User
import { useDocument } from '../hooks/useDocument';

const SignatureSuccess = ({ documentUrl, documentTitle, expiryDate, documentId }) => {
  const navigate = useNavigate();
  const [storedId, setStoredId] = useState(documentId);

  // Store documentId in state and sessionStorage when component mounts
  useEffect(() => {
    if (documentId) {
      setStoredId(documentId);
      sessionStorage.setItem('lastDocumentId', documentId);
    }
  }, [documentId]);

  const { 
    downloadDocument, 
    isDownloading 
  } = useDocument(storedId || sessionStorage.getItem('lastDocumentId'));

  const handleDownload = async () => {
    try {
      await downloadDocument();
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const handleViewDocument = () => {
    window.location.href = documentUrl;
  };

  const handleClientPortal = () => {
    navigate('/client-portal'); // This should match your route for the coming soon page
  };

  // Calculate remaining time
  const getRemainingTime = () => {
    if (!expiryDate) return null;
    
    const expiry = new Date(expiryDate);
    const now = new Date();
    const diffTime = expiry - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) return 'This link has expired';
    if (diffDays === 0) return 'This link expires today';
    if (diffDays === 1) return 'This link expires tomorrow';
    return `This link expires in ${diffDays} days`;
  };

  const formatExpiryDate = (date) => {
    if (!date) return null;
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100
      }
    }
  };

  const successIconVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 200
      }
    }
  };

  const cardHoverVariants = {
    hover: {
      y: -5,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-darkbg-900 dark:to-darkbg-800 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-3xl mx-auto">
        {/* Success Message Section */}
        <motion.div 
          variants={successIconVariants}
          className="text-center mb-12"
        >
          <div className="relative inline-block">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.1
              }}
              className="absolute -inset-1 bg-green-500 opacity-20 rounded-full blur"
            />
            <CheckCircle className="relative w-24 h-24 mx-auto text-green-500" />
          </div>
          <motion.h2 
            variants={itemVariants}
            className="mt-6 text-4xl font-bold text-gray-900 dark:text-gray-100"
          >
            Document Successfully Signed!
          </motion.h2>
          <motion.p 
            variants={itemVariants}
            className="mt-3 text-xl text-gray-600 dark:text-gray-300"
          >
            Thank you for signing <span className="font-semibold">"{documentTitle}"</span>
          </motion.p>
        </motion.div>

        {/* Link Expiration Info */}
        {/* <motion.div
          variants={itemVariants}
          className="mb-12 bg-white dark:bg-darkbg-700 rounded-lg p-6 shadow-md"
        >
          <div className="flex items-center mb-3">
            <Clock className="h-5 w-5 text-grayteck-500 mr-2" />
            <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
              Document Accessibility
            </h3>
          </div>
          <p className="text-grayteck-600 dark:text-grayteck-300 mb-2">
            {getRemainingTime()}
          </p>
          <p className="text-sm text-grayteck-500 dark:text-grayteck-400">
            Exact expiration: {formatExpiryDate(expiryDate)}
          </p>
        </motion.div> */}

        {/* Action Cards Section */}
        <motion.div 
          variants={itemVariants}
          className="grid grid-cols-1 gap-6 mt-12 sm:grid-cols-3"
        >
          {/* View Document Card */}
          <motion.button
            variants={cardHoverVariants}
            whileHover="hover"
            whileTap={{ scale: 0.98 }}
            onClick={handleViewDocument}
            className="group relative bg-white dark:bg-darkbg-700 p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex flex-col items-center">
              <div className="p-3 bg-blue-50 dark:bg-blue-900/30 rounded-full">
                <FileText className="h-8 w-8 text-blue-500 dark:text-blue-400" />
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-100">
                View Document
              </h3>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                Review your signed document
              </p>
            </div>
          </motion.button>

          {/* Download Document Card */}
          <motion.button
            variants={cardHoverVariants}
            whileHover={!isDownloading && "hover"}
            whileTap={!isDownloading && { scale: 0.98 }}
            onClick={handleDownload}
            disabled={isDownloading}
            className={`group relative bg-white dark:bg-darkbg-700 p-8 rounded-xl shadow-lg transition-all duration-300 
              ${isDownloading ? 'opacity-75 cursor-not-allowed' : 'hover:shadow-xl'}`}
          >
            <div className="flex flex-col items-center">
            <div className={`p-3 bg-green-50 dark:bg-green-900/30 rounded-full 
              ${isDownloading ? 'animate-pulse' : ''}`}>
              <Download className={`h-8 w-8 text-green-500 dark:text-green-400
                ${isDownloading ? 'animate-bounce' : ''}`} 
              />
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-100">
              {isDownloading ? 'Downloading...' : 'Download PDF'}
              </h3>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 text-center">
              {isDownloading ? 'Please wait' : 'Save a local copy'}
              </p>
            </div>
          </motion.button>

          {/* View All Documents Card */}
          <motion.button
            variants={cardHoverVariants}
            whileHover="hover"
            whileTap={{ scale: 0.98 }}
            onClick={handleClientPortal}
            className="group relative bg-white dark:bg-darkbg-700 p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex flex-col items-center">
              <div className="p-3 bg-purple-50 dark:bg-purple-900/30 rounded-full">
              <User className="h-8 w-8 text-purple-500 dark:text-purple-400" />
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-100">
                Client Portal
              </h3>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                Access all your documents
              </p>
            </div>
          </motion.button>
        </motion.div>

        {/* Additional Info */}
        <motion.p 
          variants={itemVariants}
          className="mt-8 text-center text-sm text-gray-500 dark:text-gray-400"
        >
          A confirmation email has been sent to the relevant parties.
        </motion.p>
      </div>
    </motion.div>
  );
};

export default SignatureSuccess;