import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument';
import { useQueryClient } from 'react-query';

import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import { PlusIcon, DocumentIcon, TrashIcon, LinkIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import ProgressTracker from './DocProgressTracker';
import PublishedLinkSection from './PublishedLinkSection';
import SignedDocumentPreview from './SignedDocumentPreview';
import { Download, Clock, Eye, History } from 'lucide-react';
import ErrorPage from '../pages/ErrorPage';
import CapabilitiesStyles from './CapabilitiesStyles';
import TemplateSelector from './TemplateSelector';


const DocumentAnalytics = ({ document }) => {
  // Only show analytics if the document has been shared
  if (!document?.shareable_link) {
    return null;
  }

  return (
    <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-4 sm:p-6 mb-6">
      <h3 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100 mb-4 pb-2 border-b border-grayteck-200 dark:border-grayteck-700">
        Document Analytics
      </h3>
      
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        {/* View Count */}
        <div className="bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                <Eye className="h-5 w-5 text-blue-600 dark:text-blue-400" />
              </div>
              <h4 className="ml-3 text-sm font-medium text-grayteck-900 dark:text-grayteck-100">
                Total Views
              </h4>
            </div>
            <span className="text-2xl font-bold text-grayteck-900 dark:text-grayteck-100">
              {document.open_count || 0}
            </span>
          </div>
        </div>

        {/* First View */}
        <div className="bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-4">
          <div className="flex items-center mb-2">
            <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-full">
              <Clock className="h-5 w-5 text-green-600 dark:text-green-400" />
            </div>
            <h4 className="ml-3 text-sm font-medium text-grayteck-900 dark:text-grayteck-100">
              First Viewed
            </h4>
          </div>
          <p className="text-sm text-grayteck-600 dark:text-grayteck-300">
            {document.first_opened_at 
              ? new Date(document.first_opened_at).toLocaleString()
              : 'Not viewed yet'}
          </p>
        </div>

        {/* Last View */}
        <div className="bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-4">
          <div className="flex items-center mb-2">
            <div className="p-2 bg-purple-100 dark:bg-purple-900/30 rounded-full">
              <History className="h-5 w-5 text-purple-600 dark:text-purple-400" />
            </div>
            <h4 className="ml-3 text-sm font-medium text-grayteck-900 dark:text-grayteck-100">
              Last Viewed
            </h4>
          </div>
          <p className="text-sm text-grayteck-600 dark:text-grayteck-300">
            {document.last_opened_at 
              ? new Date(document.last_opened_at).toLocaleString()
              : 'Not viewed yet'}
          </p>
        </div>
      </div>
    </div>
  );
};


const DocumentEditor = () => {
  const { id } = useParams();





  const navigate = useNavigate();
  const { 
    document, 
    isLoading, 
    isError, 
    createDocument, 
    updateDocument, 
    publishDocument,
    unpublishDocument, // Add this
    downloadDocument,
    isDownloading,
    isPublishing, // Add this
    isUnpublishing, // Add this
  } = useDocument(id);

  const queryClient = useQueryClient();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [tagline, setTagline] = useState('');
  const [secondaryTagline, setSecondaryTagline] = useState('');
  const [clientCompany, setClientCompany] = useState('');
  const [description, setDescription] = useState('');  // New state for description
  const [content, setContent] = useState('');
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [hasSignatureBox, setHasSignatureBox] = useState(false);
  const [bannerImage, setBannerImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishActionLoading, setIsPublishActionLoading] = useState(false);
  const [publishedLink, setPublishedLink] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [documentType, setDocumentType] = useState('standard');
  const [activeTemplate, setActiveTemplate] = useState('standard');
  const previewRef = useRef(null);
    // Track if this is the initial mount
    const isInitialMount = useRef(true);


    // Main document effect for state management
  useEffect(() => {
    if (document) {
      const prevContent = content;
      const prevHasSignatureBox = hasSignatureBox;
      
      // Only update states if it's the initial load or if we're not in the middle of saving
      if (isInitialMount.current || !isSaving) {
        setTitle(document.title);
        setDescription(document.description || '');
        setSubtitle(document.subtitle || '');
        setTagline(document.tagline || '');
        setSecondaryTagline(document.secondary_tagline || '');
        setClientCompany(document.receiving_company || '');
        setBannerImage(document.banner_image);
        setDocumentType(document.document_type || 'standard');
        setActiveTemplate(document.document_type || 'standard');

        // Handle signature box and content
        const hasSignature = document.content.includes('signature-container');
        const contentWithoutSignature = document.content.replace(/<div class="signature-container">[\s\S]*?<\/p>/, '').trim();
        
        setContent(contentWithoutSignature);
        setHasSignatureBox(hasSignature);

        // Only reset saved states if we're not in the middle of a save operation
        if (!isSaving) {
          setIsSaved(true);
          setHasChanges(false);
        }
      }

      if (isInitialMount.current) {
        isInitialMount.current = false;
      }
    }
  }, [document]);

  // Change detection effect
  useEffect(() => {
    if (!isInitialMount.current && !isSaving) {
      const currentContent = hasSignatureBox ? content + signatureBoxHtml : content;
      const documentContent = document?.content || '';
      
      const hasContentChanged = currentContent !== documentContent;
      const hasTitleChanged = title !== document?.title;
      const hasDescriptionChanged = description !== (document?.description || '');
      const hasBannerChanged = bannerImage !== document?.banner_image;
      const hasSignatureBoxChanged = hasSignatureBox !== document?.content.includes('signature-container');
    // Add checks for capabilities-specific fields
    const hasSubtitleChanged = subtitle !== (document?.subtitle || '');
    const hasTaglineChanged = tagline !== (document?.tagline || '');
    const hasSecondaryTaglineChanged = secondaryTagline !== (document?.secondary_tagline || '');
    
    const hasAnyChange = 
      hasContentChanged || 
      hasTitleChanged || 
      hasDescriptionChanged || 
      hasBannerChanged ||
      hasSignatureBoxChanged ||
      hasSubtitleChanged ||
      hasTaglineChanged ||
      hasSecondaryTaglineChanged;
      
      if (hasAnyChange) {
        setHasChanges(true);
        setIsSaved(false);
      }
    }
  }, [
    title,
    content,
    description,
    bannerImage,
    hasSignatureBox,
    subtitle,       // Add capabilities fields to dependencies
    tagline,
    secondaryTagline,
    document,
    isSaving
  ]);


  

  


  const loadSignaturePadScript = () => {
    if (typeof window !== 'undefined' && window.document) {
      const script = window.document.createElement('script');
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.5.3/signature_pad.min.js";
      script.async = true;
      window.document.body.appendChild(script);

      script.onload = initializeSignaturePad;

      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    }
  };

// Update handlePublish and add handleUnpublish
const handlePublishAction = async () => {
  if (!id) {
    toast.error("Please save the document before publishing.");
    return;
  }
  if (!title || !content) {
    toast.error('Please provide a title and content before publishing.');
    return;
  }

  setIsPublishActionLoading(true);
  try {
    if (document?.shareable_link) {
      // Unpublish
      await unpublishDocument();
    } else {
      // Publish
      const publishedDoc = await publishDocument();
      const token = publishedDoc.shareable_link.split('/').pop();
      const frontendUrl = `${window.location.origin}/share/${token}`;
      setPublishedLink(frontendUrl);
    }
  } catch (error) {
    console.error("Publish/Unpublish error:", error);
    toast.error(`Failed to ${document?.shareable_link ? 'unpublish' : 'publish'} document. Please try again.`);
  } finally {
    setIsPublishActionLoading(false);
  }
};

// Update the action buttons section in the render

  useEffect(() => {
    if (document) {
      setTitle(document.title);
      setDescription(document.description || '');  // Set description
      setSubtitle(document.subtitle || '');
      setTagline(document.tagline || '');
      setSecondaryTagline(document.secondary_tagline || '');
      const contentWithoutSignature = document.content.replace(/<div class="signature-container">[\s\S]*?<\/p>/, '').trim();
      setContent(contentWithoutSignature);
      setBannerImage(document.banner_image);
      setHasSignatureBox(document.content.includes('signature-container'));
      setIsSaved(true);
      setHasChanges(false); // Reset hasChanges after loading document

      setDocumentType(document.document_type || 'standard');
      setActiveTemplate(document.document_type || 'standard')
    }
  }, [document]);

  // Reset saved state when content changes
  useEffect(() => {
    if (!isInitialMount.current) {
      setIsSaved(false);
      setHasChanges(true);  // Set hasChanges to true when content changes
    } else {
      isInitialMount.current = false;
    }
  }, [title, content, description, bannerImage, hasSignatureBox]);

// Also update your handleSave function to reset hasChanges
const handleSave = async () => {
  if (isSaving || (isSaved && !hasChanges)) return;

    let fullContent = content;
    
    if (hasSignatureBox) {
      fullContent += signatureBoxHtml;
    }
  
  // Base document data
  const documentData = {
    title,
    description,
    content: fullContent,
    banner_image: bannerImage,
    document_type: documentType,
  };

  // Add template-specific fields
  if (activeTemplate === 'capabilities') {
    documentData.subtitle = subtitle;
    documentData.tagline = tagline;
    documentData.secondary_tagline = secondaryTagline;
  } else if (activeTemplate === 'proposal') {
    documentData.subtitle = subtitle;
    documentData.poc_name = tagline;
    documentData.poc_role = secondaryTagline;
    documentData.receiving_company = clientCompany;
  }


    setIsSaving(true);
    try {
      if (!id) {
        // Creating new document
        const newDoc = await createDocument(documentData);

        if (newDoc?._id) {
          // Navigate first
          navigate(`/edit/${newDoc._id}`, { replace: true });
          // Let the document effect handle state updates
          await queryClient.invalidateQueries(['document', newDoc._id]);
          toast.success("Document created successfully!");

        }
      } else {
        // Updating existing document
        await updateDocument({ id, ...documentData });

        // Don't immediately reset states - wait for the effect to complete
      queryClient.setQueryData(['document', id], (oldData) => ({
        ...oldData,
        ...documentData,
      }));
        setIsSaved(true);
        setHasChanges(false);  // Reset hasChanges after successful save
        toast.success("Document updated successfully!");
      }
    } catch (error) {
      console.error('Save error:', error);
      toast.error("Failed to save document. Please try again.");
      setIsSaved(false);
      setHasChanges(true);
    } finally {
      setIsSaving(false);
    }
  };

  
  
// Add a new effect to handle navigation success
useEffect(() => {
  if (document) {
    const hadChanges = hasChanges; // Store current change state
    
    setTitle(document.title);
    setDescription(document.description || '');
    setSubtitle(document.subtitle || '');

       // Set specific values for the "proposal" document type
       if (document.document_type === "proposal") {
        setTagline(document.poc_name || '');  // Set value for "Point of Contact Name"
        setSecondaryTagline(document.poc_role || '');  // Set value for "Point of Contact Role"
      } else {
        setTagline(document.tagline || '');  // Standard tagline
        setSecondaryTagline(document.secondary_tagline || '');  // Standard secondary tagline
      }
    
    const contentWithoutSignature = document.content.replace(/<div class="signature-container">[\s\S]*?<\/p>/, '').trim();
    setContent(contentWithoutSignature);
    setBannerImage(document.banner_image);
    setHasSignatureBox(document.content.includes('signature-container'));
    setDocumentType(document.document_type || 'standard');
    setActiveTemplate(document.document_type || 'standard');
    
    // Only reset states if there were no changes in progress
    if (!hadChanges) {
      setIsSaved(true);
      setHasChanges(false);
    }
  }
}, [document]);

  const handleDownloadPDF = async () => {
    try {
      await downloadDocument();
    } catch (error) {
      console.error('Failed to download PDF:', error);
    }
  };


  const handleBack = () => {
    navigate('/');
};


  const signatureBoxHtml = `
    <div class="signature-container">
      <div class="input-group">
        <label for="name-input"><strong>Point of Contact Name:</strong></label>
        <input type="text" id="name-input" class="poc-input">
      </div>
      <canvas id="signature-pad" width="500" height="100"></canvas>
      <div class="button-group">
        <button id="clear-button" class="action-button">Clear Signature</button>
        <button id="sign-button" class="action-button" disabled>Sign Document</button>
        <button id="download-button" class="action-button">Download as PDF</button>
      </div>
    </div>
    <p><strong>Date Signed:</strong> <span id="sign-date"></span></p>
  `;

  

  

  const initializeSignaturePad = () => {
    if (previewRef.current && window.SignaturePad) {
      const canvas = previewRef.current.querySelector('#signature-pad');
      if (!canvas) {
        console.error('Signature pad canvas not found');
        return;
      }

      const signaturePad = new window.SignaturePad(canvas, {
        minWidth: 1,
        maxWidth: 3,
        penColor: "black"
      });

        const clearButton = previewRef.current.querySelector('#clear-button');
        const signDateElement = previewRef.current.querySelector('#sign-date');
        const nameInput = previewRef.current.querySelector('#name-input');
        const signButton = previewRef.current.querySelector('#sign-button');

        const clearAll = () => {
          signaturePad.clear();
          if (signDateElement) {
            signDateElement.textContent = '';
          }
          if (nameInput) {
            nameInput.value = '';
          }
          if (signButton) {
            signButton.disabled = true;
          }
        };

        if (clearButton) {
          clearButton.addEventListener('click', clearAll);
        }

      const generateSignature = (name) => {
        if (!canvas) {
          console.error('Canvas not found when generating signature');
          return;
        }
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          console.error('Unable to get 2D context from canvas');
          return;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = "italic 30px 'Brush Script MT', cursive";
        ctx.fillStyle = 'black';
        ctx.fillText(name, 20, canvas.height / 2);
      };

      if (nameInput && signButton) {
        nameInput.addEventListener('input', function() {
          const name = this.value.trim();
          signButton.disabled = name === '';
          if (name !== '') {
            generateSignature(name);
          } else {
            signaturePad.clear();
          }
        });
      }

      if (signButton) {
        signButton.addEventListener('click', () => {
          const signDate = new Date().toLocaleDateString();
          if (signDateElement) {
            signDateElement.textContent = signDate;
          }
          alert('Document signed on ' + signDate);
        });
      }

      const downloadButton = previewRef.current.querySelector('#download-button');
      // const downloadButton = previewRef.current.querySelector('#download-button');
      if (downloadButton) {
        downloadButton.addEventListener('click', async () => {
          try {
            await downloadDocument();  // Use the hook instead of direct fetcher
          } catch (error) {
            console.error('Failed to download PDF:', error);
          }
        });
      }

      // if (downloadButton) {
      //   downloadButton.addEventListener('click', () => window.print());
      // }
    }
    }
  

  //   useEffect(() => {
  //     if (!isInitialMount.current) {
  //       const currentContent = hasSignatureBox ? content + signatureBoxHtml : content;
  //       const documentContent = document?.content || '';
        
  //       const hasContentChanged = currentContent !== documentContent;
  //       const hasTitleChanged = title !== document?.title;
  //       const hasDescriptionChanged = description !== (document?.description || '');
  //       const hasBannerChanged = bannerImage !== document?.banner_image;
  //       const hasSignatureBoxChanged = hasSignatureBox !== document?.content.includes('signature-container');
        
  //       const hasAnyChange = 
  //         hasContentChanged || 
  //         hasTitleChanged || 
  //         hasDescriptionChanged || 
  //         hasBannerChanged ||
  //         hasSignatureBoxChanged;
        
  //       setHasChanges(hasAnyChange);
  //       if (hasAnyChange) {
  //         setIsSaved(false);
  //       }
  //     } else {
  //       isInitialMount.current = false;
  //     }
  //   }, [title, content, description, bannerImage, hasSignatureBox, document]);

  // useEffect(() => {
  //   if (document) {
  //     setTitle(document.title);
  //     setContent(document.content);
  //     setBannerImage(document.banner_image);

  //     // Check if the document content contains the signature box
  //     if (document.content.includes('signature-container')) {
  //       setHasSignatureBox(true);
  //       // Remove the signature box from the content for editing
  //       setContent(document.content.replace(/<div class="signature-container">[\s\S]*?<\/p>/, ''));
  //     } else {
  //       setHasSignatureBox(false);
  //       setContent(document.content);
  //     }
  //   }
  // }, [document]);
  
//   const handleSave = () => {
//     onSave({ title, content: getFullContent(), bannerImage });
// const handleCancel = () => {
//     navigate('/');
// };

  const togglePreviewMode = () => {
    setIsPreviewMode(!isPreviewMode);
  };

  const addCompanyBranding = (documentContent) => {
    const brandingHtml = `
      <div class="company-branding">
        <p>GrayTecknologies LLC | Innovative Web Solutions</p>
      </div>
    `;
    return documentContent + brandingHtml;
  };

  const getFullContent = () => {
    let fullContent = '';
    if (activeTemplate === 'capabilities') {
      // Capabilities template cover page
      fullContent += `
        <div class="cover-page">
          <h1>${title}</h1>
          <h2>${subtitle}</h2>
          <p>${tagline}</p>
          ${secondaryTagline ? `<p>${secondaryTagline}</p>` : ''}
        </div>
      `;
      
      // Add main content
      fullContent += content;
      
      // Add signature box if needed
      if (hasSignatureBox) {
        fullContent += signatureBoxHtml;
      }
      
      // Add company footer
      fullContent += `
        <div class="company-footer">
          <div class="company-name">GrayTecknologies LLC</div>
          <div class="company-tagline">Innovative Web Solutions</div>
        </div>
      `;
    }else if (activeTemplate === 'proposal') {
      // Capabilities template cover page
      fullContent += `
        <div class="cover-page">
          <h1>${title}</h1>
          <h2>${subtitle}</h2>
          <p>Prepared for: ${tagline}</p>
          ${secondaryTagline ? `<p>${secondaryTagline}, ${clientCompany}</p>` : ''}
        </div>
      `;
      
      // Add main content
      fullContent += content;
      
      // Add signature box if needed
      if (hasSignatureBox) {
        fullContent += signatureBoxHtml;
      }
      
      // Add company footer
      fullContent += `
        <div class="company-footer">
          <div class="company-name">GrayTecknologies LLC</div>
          <div class="company-tagline">Innovative Web Solutions</div>
        </div>
      `;
    }else{

      // Add banner image if exists
      if (bannerImage) {
        fullContent += `<div class="document-banner"><img src="${bannerImage}" alt="Document Banner" /></div>`;
      }
      
      // Add title
      fullContent += `<h1 style="margin-top: 1.5em; margin-bottom: 1em;">${title}</h1>`;
      
      // Add main content
      fullContent += `<div class="document-body">${content}`;
      
      if (hasSignatureBox) {
        fullContent += signatureBoxHtml;
      }
      
      // Add a subtle line break
      fullContent += '<hr class="footer-divider">';
      
      // Add enhanced company branding
      fullContent += `
        <div class="company-branding">
          <p class="company-name">GrayTecknologies LLC</p>
          <p class="company-tagline">Innovative Web Solutions</p>
        </div>
      `;
      
      fullContent += '</div>';
    }
    
    return fullContent;
  };

  const toggleSignatureBox = () => {
    setHasSignatureBox(!hasSignatureBox);
    setHasChanges(true);  // Explicitly set hasChanges when signature box is toggled
    setIsSaved(false);    // Indicate that there are unsaved changes
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeBannerImage = () => {
    setBannerImage(null);
  };
  
  const previewStyles = `
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
      background-color: #f0f0f0;
      margin: 0;
      padding: 20px;
    }
    .document-container {
      max-width: 800px;
      margin: 40px auto;
      background-color: white;
      box-shadow: 0 4px 10px rgba(0,0,0,0.1);
      border-radius: 8px;
      overflow: hidden;
      position: relative;
    }
    .document-content {
      padding: 0;
      line-height: 1.6;
      color: black;
    }
    .document-content::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to right bottom, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 60%);
      pointer-events: none;
    }
    .back-button {
      position: fixed;
      top: 20px;
      left: 20px;
      padding: 10px 20px;
      background-color: #333;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }
    .back-button:hover {
      background-color: #555;
    }
    h1 {
      color: #4a5568;
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }
    h2 {
      color: #4a5568;
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 10px;
    }
    strong {
      font-weight: bold;
    }
    ol, ul {
      margin-bottom: 15px;
      padding-left: 30px;
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: disc;
    }
    li {
      margin-bottom: 5px;
      padding-left: 5px;
    }
    ol ol, ul ul, ol ul, ul ol {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .company-header {
      text-align: center;
      margin-bottom: 30px;
    }
    .company-name {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .company-tagline {
      font-style: italic;
      color: #718096;
    }
    .signature-container {
      margin-top: 30px;
      border-top: 1px solid #e2e8f0;
      padding-top: 20px;
    }
    .input-group {
      margin-bottom: 10px;
    }
    .poc-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
    }
    #signature-pad {
      border: 1px solid #000;
      width: 100%;
      margin-bottom: 10px;
    }
    .button-group {
      display: flex;
      justify-content: space-between;
    }
    .action-button {
      padding: 10px 15px;
      background-color: #4a5568;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
    }
    .action-button:hover {
      background-color: #2d3748;
    }
    .action-button:disabled {
      background-color: #a0aec0;
      cursor: not-allowed;
    }
    .company-branding {
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid #e2e8f0;
      text-align: center;
      font-size: 14px;
      color: #718096;
    }
    .document-banner {
      width: 100%;
      height: 200px;
      overflow: hidden;
    }
    .document-banner img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .document-body {
      padding: 40px;
      color: #333; /* Ensure text color is dark */
    }
    h1 {
      color: #4a5568;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 20px;
      text-align: center;
    }
    p, ul, ol, li {
      color: #333; /* Ensure all text elements have a dark color */
    }
    .footer-divider {
      border: 0;
      background: rgba(0, 0, 0, 0.1);
      margin: 20px 0 10px;
    }

    .company-branding {
      text-align: center;
      margin-top: 10px;
      color: #555;
      font-size: 14px;
    }

    .company-name {
      font-weight: bold;
      margin-bottom: 2px;
    }

    .company-tagline {
      font-style: italic;
    }

    @media print {
      .footer-divider,
      .company-branding {
        position: fixed;
        bottom: 20px;
        left: 0;
        right: 0;
      }
    }
  `;

  useEffect(() => {
    if (isPreviewMode) {
      return loadSignaturePadScript();
    }
  }, [isPreviewMode]);

  // If document is signed, show the preview mode
  if (document?.signed_by) {
      console.log("Document is signed, passing to SignedDocumentPreview:", document);
    return (
      <SignedDocumentPreview
        document={document}
        onBack={handleBack}
        // onPublish={handlePublish}
      />
    );
  }

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <ErrorPage 
    title="Document Error"
    message="Failed to load the document. Please try again later."
    errorCode="500"
  />;

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8 bg-gray-100 dark:bg-darkbg-900 min-h-screen">
    {isPreviewMode ? (
      <div className="fixed inset-0 bg-gray-50 dark:bg-darkbg-900 overflow-auto">
    {activeTemplate === 'capabilities' || activeTemplate === 'proposal' ? <CapabilitiesStyles /> : <style>{previewStyles}</style>}    <button 
      onClick={togglePreviewMode} 
      className="fixed top-4 left-4 px-4 py-2 bg-white dark:bg-darkbg-800 shadow-lg rounded-md flex items-center gap-2 z-50 hover:bg-gray-50 dark:hover:bg-darkbg-700 transition-colors"
    >
      <ArrowLeftIcon className="h-4 w-4" />
      Back to Editor
    </button>
    
    { activeTemplate === 'capbilities' || "proposal" ? (
      <div className="max-w-[1200px] mx-auto">
      <div 
        ref={previewRef} 
        className="document-content"
        dangerouslySetInnerHTML={{ __html: getFullContent() }}
      />
    </div>
    ) : (
      <div className="max-w-[1200px] mx-auto my-8 bg-white dark:bg-darkbg-800 shadow-xl rounded-lg overflow-hidden">
      <div 
        ref={previewRef} 
        className="document-content"
        dangerouslySetInnerHTML={{ __html: getFullContent() }}
      />
    </div>
    )}
    
  </div>
    ) : (
      <>
        {/* Back Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleBack}
          className="mb-4 flex items-center px-3 py-2 sm:px-4 sm:py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 transition-colors duration-200 shadow-md text-sm sm:text-base"
        >
          <ArrowLeftIcon className="h-4 w-4 sm:h-5 sm:w-5 mr-2" />
          Back to Documents
        </motion.button>

        {/* Progress Tracker */}
        {!isLoading && !isError && (
          <>
        <ProgressTracker document={document || {}} />
        <DocumentAnalytics document={document || {}} />
          </>
        )}

        
        {/* Main Editor Container */}
        <div className="bg-white dark:bg-darkbg-800 shadow-lg rounded-lg p-3 sm:p-6">
        <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-grayteck-900 dark:text-grayteck-100 border-b pb-2">
          {id ? 'Edit Document' : 'Create Document'}
        </h1>

        {/* Form Fields */}
        <div className="space-y-4 sm:space-y-6">
          {/* Title Input */}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
              Document Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
              placeholder="Enter document title"
            />
          </div>

          {/* Additional Form Fields for Capabilities Template */}
          {(activeTemplate === 'capabilities' || activeTemplate === 'proposal') && (
            <div className="space-y-4">
              <div>
                <label htmlFor="subtitle" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
                  Document Subtitle
                </label>
                <input
                  type="text"
                  id="subtitle"
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                  className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
                  placeholder="e.g., Technical Capabilities Statement"
                />
              </div>

              <div>
                <label htmlFor="tagline" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
                  {documentType === "proposal" ? "Point of Contact" : "Document Tagline"}
                </label>
                <input
                  type="text"
                  id="tagline"
                  value={tagline}
                  onChange={(e) => setTagline(e.target.value)}
                  className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
                  placeholder={documentType === "proposal" ? "Enter Point of Contact Name" :  "e.g., Empowering Businesses Through Technology"}
              />
              </div>

              <div>
                <label htmlFor="secondaryTagline" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
                {documentType === "proposal" ? "Point of Contact Role" : "Secondary Tagline (Optional)"}
                </label>
                <input
                  type="text"
                  id="secondaryTagline"
                  value={secondaryTagline}
                  onChange={(e) => setSecondaryTagline(e.target.value)}
                  className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
                  placeholder={documentType == "proposal" ? "Enter Point of Contact Role" : "e.g., Innovative Web Solutions"}
                />
              </div>

              {activeTemplate === 'proposal' && (
                <div>
                  <label htmlFor="clientCompany" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
                    Client Company (optional)
                  </label>
                  <input
                    type="text"
                    id="clientCompany"
                    value={clientCompany}
                    onChange={(e) => setClientCompany(e.target.value)}
                    className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
                    placeholder="Enter client company (optional)" />
                </div>
              )}
            </div>
          )}

          {/* Description Input */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
              Document Description (optional)
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
              placeholder="Enter document description (optional)"
              rows="3"
            ></textarea>
            <label htmlFor="banner-image" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
              Banner Image
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md bg-gray-50 dark:bg-darkbg-700 dark:border-darkbg-600">
              <div className="space-y-1 text-center">
                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-grayteck-600 hover:text-grayteck-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-grayteck-500">
                    <span>Upload a file</span>
                    <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleImageUpload} accept="image/*" />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
              </div>
            </div>
            {bannerImage && (
              <div className="mt-4 p-4 bg-gray-100 dark:bg-darkbg-700 rounded-md shadow-inner">
                <div className="flex items-center">
                  <img src={bannerImage} alt="Banner preview" className="w-40 h-24 object-cover rounded mr-4" />
                  <div>
                    <p className="text-sm text-grayteck-600 dark:text-grayteck-300 mb-2">Banner image selected</p>
                    <button
                      onClick={removeBannerImage}
                      className="px-3 py-1 bg-red-500 text-white text-sm rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 shadow-sm"
                    >
                      Remove Banner
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Content Input */}
          <div>
            <label htmlFor="content" className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
              Document Content (HTML)
            </label>

            <TemplateSelector 
              activeTemplate={activeTemplate}
              onTemplateChange={(templateType)=> {
                setDocumentType(templateType)
                setActiveTemplate(templateType)
              }
              }
            />
            
            <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows="10"
              className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
              // placeholder="Enter HTML content"
              placeholder={activeTemplate === 'capabilities' ? 
                `<!-- Cover Page -->
          <div class="cover-page">
            <h1>Title</h1>
            <h2>Subtitle</h2>
          </div>
          
          <!-- Content Sections -->
          <div class="section">
            <h1>Section Title</h1>
            
            <!-- Service Cards -->
            <div class="service-grid">
              <div class="service-card">
                <h3>Service Title</h3>
                <p>Description</p>
              </div>
            </div>
          
            <!-- Highlight Box -->
            <div class="highlight-box">
              Important content here
            </div>
          
            <!-- Tech List -->
            <div class="tech-list">
              <span class="tech-item">Technology</span>
            </div>
          </div>` : 
                "Enter HTML content"}
            />
          </div>
        </div>

        {/* Document Status Sections */}
        <PublishedLinkSection document={document} />

        {/* Action Buttons Container */}
        <div className="mt-6 border-t pt-4">
          {/* Primary Action Buttons - Stack on mobile, row on desktop */}
          <div className="flex flex-col sm:flex-row sm:justify-end gap-2 sm:gap-3">
            <button
              onClick={toggleSignatureBox}
              className={`w-full sm:w-auto px-4 py-2 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-md text-sm sm:text-base ${
                hasSignatureBox 
                  ? 'bg-red-500 hover:bg-red-600 focus:ring-red-300' 
                  : 'bg-grayteck-500 hover:bg-grayteck-600 focus:ring-grayteck-300'
              }`}
            >
              {hasSignatureBox ? 'Remove Signature Box' : 'Add Signature Box'}
            </button>
            
              <button
                onClick={togglePreviewMode}
                className="w-full sm:w-auto px-4 py-2 bg-grayteck-500 text-white rounded-md hover:bg-grayteck-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-400 shadow-md text-sm sm:text-base"
                >
                Preview
              </button>
              {/* Only show download button if document exists and has content */}
              {id && content && (
                <button
                  onClick={handleDownloadPDF}
                  disabled={isDownloading}
                  className="w-full sm:w-auto px-4 py-2 bg-grayteck-500 text-white rounded-md hover:bg-grayteck-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-400 shadow-md text-sm sm:text-base inline-flex items-center justify-center"
                >
                  {isDownloading ? (
                    <>
                      <span className="animate-spin mr-2">⏳</span>
                      Downloading...
                    </>
                  ) : (
                    <>
                      <Download className="w-4 h-4 mr-2" />
                      Download PDF
                    </>
                  )}
                </button>
              )}

              <button
                onClick={handleSave}
                disabled={isSaving || (isSaved && !hasChanges)}
                className={`w-full sm:w-auto px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-500 shadow-md text-sm sm:text-base ${
                  (isSaving || (isSaved && !hasChanges)) ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                >
                {isSaving ? 'Saving...' : 
                 isSaved && !hasChanges ? 'Saved' : 
                 id ? 'Update Document' : 'Save Document'}
            </button>

               {/* Only show publish button if document isn't already published */}
                {/* Publish/Unpublish Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handlePublishAction}
          disabled={isPublishActionLoading || !id}
          className={`
            w-full sm:w-auto px-4 py-2 rounded-md 
            focus:outline-none focus:ring-2 focus:ring-offset-2 
            shadow-md text-sm sm:text-base
            flex items-center justify-center
            transition-colors duration-200
            ${document?.shareable_link
              ? 'bg-orange-500 hover:bg-orange-600 focus:ring-orange-400'
              : 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
            }
            ${(isPublishActionLoading || !id) ? 'opacity-50 cursor-not-allowed' : ''}
            text-white
          `}
        >
          {isPublishActionLoading ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
              {document?.shareable_link ? 'Unpublishing...' : 'Publishing...'}
            </>
          ) : (
            <>
              {document?.shareable_link ? (
                <>
                  <LinkIcon className="h-4 w-4 mr-2 rotate-90" />
                  Unpublish
                </>
              ) : (
                <>
                  <LinkIcon className="h-4 w-4 mr-2" />
                  Publish
                </>
              )}
            </>
          )}
        </motion.button>
            </div>
            </div>
            </div>
      </>
    )}
  </div>
);
};
export default DocumentEditor;