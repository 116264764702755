import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { motion } from 'framer-motion';
import { UserCheck, Calendar, Eye, ArrowLeft, Download, Share2 } from 'lucide-react';
import { toast } from 'react-toastify';
import { format, parseISO, subHours } from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { useDocument } from '../hooks/useDocument';
// Remove this import as we'll use the hook
// import { downloadDocumentAsPDF } from '../functions/api_fethcers';

const SignedDocumentPreview = () => {
  const { id } = useParams();
  const { 
    document, 
    isLoading, 
    isError, 
    error,
    downloadDocument, // Add the download mutation
    isDownloading    // Add loading state
  } = useDocument(id);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    if (!document) {
      console.log("No document provided");
    } else {
      console.log("Document provided:", document);
    }
  }, [document]);

  
  
  console.log('Signerdd preview!!!!')
  // Format full date and time from UTC to EDT
  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    
    try {
      // Parse the UTC date string
      const date = parseISO(dateString);
      
      // Format in EDT timezone with timezone indicator
      return formatInTimeZone(
        date,
        'America/New_York',
        'EEEE, MMMM d, yyyy h:mm a zzz'
      );
    } catch (error) {
      console.error('Date formatting error:', error);
      return dateString;
    }
  };

  // Simple date format for document display
  const formatSimpleDate = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = parseISO(dateString);
      return formatInTimeZone(
        date,
        'America/New_York',
        'MM/dd/yyyy'
      );
    } catch (error) {
      console.error('Simple date formatting error:', error);
      return dateString;
    }
  };


  useEffect(() => {
    console.log('Document data:', document);
  }, [document]);

  

  // Add this useEffect to debug the dates
useEffect(() => {
    if (document?.last_opened_at) {
        console.log('Raw UTC date:', document.last_opened_at);
        console.log('Parsed local time:', formatDateTime(document.last_opened_at));
      }
    }, [document?.last_opened_at]);


  const handlePrint = () => {
    window.print();
  };


  const handleDownload = async () => {
    try {
      await downloadDocument();
      // No need for success toast as it's handled in the hook
    } catch (err) {
      // Optional: Add any component-specific error handling here
      console.error('Error downloading PDF:', err);
    }
  };

  const copyShareableLink = async () => {
    if (!document.shareable_link) return;
    
    const token = document.shareable_link.split('/').pop();
    const frontendUrl = `${window.location.origin}/share/${token}`;
    
    try {
      await navigator.clipboard.writeText(frontendUrl);
      toast.success('Link copied to clipboard!');
    } catch (err) {
      toast.error('Failed to copy link');
    }
  };

  // Function to transform the HTML content for signed view
  const transformContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    
    // Remove the signature action buttons container
    const buttonContainer = doc.querySelector('.signature-container .button-group');
    if (buttonContainer) {
      buttonContainer.remove();
    }
    
     // Remove the signature pad canvas
  const signaturePad = doc.querySelector('#signature-pad');
  if (signaturePad) {
    signaturePad.remove();
  }

    // Replace POC input with display text
    const pocInput = doc.querySelector('.signature-container .input-group input');
    if (pocInput) {
      const pocValue = document.signed_by || '';
      const pocDisplay = doc.createElement('span');
      pocDisplay.className = 'poc-display text-gray-900 dark:text-gray-100 text-lg font-medium';
      pocDisplay.textContent = pocValue;
      
      const inputContainer = pocInput.parentElement;
      if (inputContainer) {
        // Replace the input with the display span
        pocInput.replaceWith(pocDisplay);
      }
    }

    // Update the date signed
    const signDateSpan = doc.querySelector('#sign-date');
    if (signDateSpan && document.signed_at) {
      signDateSpan.textContent = formatSimpleDate(document.signed_at);
      signDateSpan.className = 'date-signed text-gray-900 dark:text-gray-100';
    }
    
    return doc.body.innerHTML;
  };
  

    // Check if document is still loading or if there's an error
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;


  return (
    <div className="min-h-screen bg-gray-50 dark:bg-darkbg-900">
      {/* Header */}
      <div className="bg-white dark:bg-darkbg-800 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={handleBack} // Use handleBack to go back
                className="mr-4 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-darkbg-700 transition-colors duration-200"
              >
                <ArrowLeft className="h-5 w-5 text-gray-600 dark:text-gray-300" />
              </button>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                Signed Document
              </h1>
            </div>
            <div className="flex items-center space-x-3">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDownload}
                disabled={isDownloading}
                className={`inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-darkbg-700 hover:bg-gray-50 dark:hover:bg-darkbg-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-500 ${
                  isDownloading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <Download className="h-4 w-4 mr-2" />
                {isDownloading ? 'Downloading...' : 'Download PDF'}
              </motion.button>
              {document?.shareable_link && (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={copyShareableLink}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-grayteck-600 hover:bg-grayteck-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-500"
                >
                  <Share2 className="h-4 w-4 mr-2" />
                  Copy Link
                </motion.button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Document Container */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-sm overflow-hidden">
          {/* Banner Header */}
          {document?.banner_image && (
            <div className="relative h-48 w-full overflow-hidden">
              <img 
                src={document.banner_image} 
                alt="Document Banner"
                className="w-full h-full object-cover"
              />
              {/* Semi-transparent overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-20" />
              {/* Document Title Overlay */}
              <div className="absolute inset-0 flex items-center justify-center">
                <h2 className="text-3xl font-bold text-white text-center px-4 text-shadow-lg">
                  {document.title}
                </h2>
              </div>
            </div>
          )}

          {/* If no banner image, show title normally */}
          {!document.banner_image && (
            <div className="px-6 pt-6">
              <h2 className="text-2xl font-bold text-center text-gray-900 dark:text-gray-100 mb-6">
                {document.title}
              </h2>
            </div>
          )}

          {/* Signature Information Card */}
          <div className="px-6 py-6 bg-gray-50 dark:bg-darkbg-700 border-b border-gray-200 dark:border-gray-600 print:hidden">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Signer Info */}
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-full">
                    <UserCheck className="h-6 w-6 text-green-600 dark:text-green-400" />
                  </div>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">Signed By</h3>
                  <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">{document.signed_by}</p>
                </div>
              </div>

              {/* Signature Date */}
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                    <Calendar className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">Date Signed</h3>
                  <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
                    {formatDateTime(document.signed_at)}
                  </p>
                </div>
              </div>

              {/* Document Opens */}
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="p-2 bg-purple-100 dark:bg-purple-900/30 rounded-full">
                    <Eye className="h-6 w-6 text-purple-600 dark:text-purple-400" />
                  </div>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">Document Opens</h3>
                  <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
                    {document.open_count} times
                  </p>
                  {document.first_opened_at && (
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                      First opened: {formatDateTime(document.first_opened_at)}
                    </p>
                  )}
                  {document.last_opened_at && (
                    <p className="mt-0.5 text-xs text-gray-500 dark:text-gray-400">
                      Last opened: {formatDateTime(document.last_opened_at)}
                    </p>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            
          {/* Document Content */}
          <div className="px-6 py-6">
            <div 
              className="prose dark:prose-invert max-w-none"
              dangerouslySetInnerHTML={{ 
                __html: transformContent(document.content) 
              }}
            />
            
            {/* Signature Image */}
            <div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-700">
              <div className="max-w-md mx-auto">
                <div className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Signature
                </div>
                <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
                  {document.signature_data && (
                    <img 
                      src={document.signature_data} 
                      alt="Signature" 
                      className="max-h-24 mx-auto"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .poc-display {
            display: inline-block;
            padding: 0.5rem 0;
            margin-left: 0.5rem;
         }
        .text-shadow-lg {
          text-shadow: 0 2px 4px rgba(0,0,0,0.3);
        }
          
          .date-signed {
            display: inline-block;
            margin-left: 0.5rem;
            font-weight: 500;
          }
          
          @media print {
            .poc-display,
            .date-signed {
              color: #000 !important;
            }
          }
        `}</style>
        
        {/* Rest of the component remains unchanged */}
      </div>
    // </div>
  );
};

export default SignedDocumentPreview;