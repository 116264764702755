import { createAxiosInstance } from './axiosInstance';
import { authStorage } from './auth';

// const authAxios = createAxiosInstance(`${process.env.REACT_APP_API_LOCAL_BASE_URL}/auth`);
const authAxios = createAxiosInstance(`${process.env.REACT_APP_API_PROD_BASE_URL}/auth`);

export const registerUser = async (username, email, password) => {
    try {
      const response = await authAxios.post('/register', { username, email, password });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };
  
  export const loginUser = async (username, password) => {
    try {
      // Create form data exactly as FastAPI expects it
      const params = new URLSearchParams({
        // grant_type: 'password',  // OAuth2 requires this
        username: username,
        password: password,
      });
  
      const response = await authAxios.post('/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
  
      console.log('Login response:', response.data);
  
      const { access_token, refresh_token, token_type, expires_in } = response.data;
  
      // Store auth data
      const expiresAt = new Date(Date.now() + (expires_in || 1800) * 1000).toISOString();
      authStorage.set({
        accessToken: access_token,
        refreshToken: refresh_token,
        tokenType: token_type,
        expiresAt
      });
  
      return response.data;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  };

  export const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await authAxios.post('/refresh', {
        current_token: refreshToken  // Send as JSON object
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };
  
  export const logoutUser = async () => {
    try {
      const refreshToken = authStorage.getRefreshToken();
      if (refreshToken) {
        await authAxios.post('/logout', { refresh_token: refreshToken });
      }
      authStorage.clear();
    } catch (error) {
      authStorage.clear();
      throw error.response ? error.response.data : error.message;
    }
  };
  
  
  export const getCurrentUser = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await authAxios.get('/me', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };