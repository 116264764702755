import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, ArrowLeft, Home } from 'lucide-react';

const ErrorPage = ({ 
  title = "Document Not Found",
  message = "The document you're looking for might have been deleted or is no longer accessible.",
  showHomeButton = true,
  showBackButton = true,
  errorCode = "404"
}) => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-darkbg-900 dark:to-darkbg-800 flex items-center justify-center px-4 py-12">
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-lg w-full"
      >
        <div className="text-center">
          <motion.div
            variants={itemVariants}
            className="relative inline-block mb-6"
          >
            <div className="absolute -inset-1 bg-red-500 opacity-20 rounded-full blur"></div>
            <AlertCircle className="relative w-16 h-16 text-red-500" />
          </motion.div>

          <motion.div
            variants={itemVariants}
            className="space-y-3 mb-8"
          >
            <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">
              {title}
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              {errorCode}
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              {message}
            </p>
          </motion.div>

          <motion.div 
            variants={itemVariants}
            className="flex flex-col sm:flex-row justify-center gap-4"
          >
            {showBackButton && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate(-1)}
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-grayteck-600 hover:bg-grayteck-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-500 transition-colors duration-200"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Go Back
              </motion.button>
            )}
            
            {showHomeButton && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate('/')}
                className="inline-flex items-center justify-center px-6 py-3 border border-grayteck-300 dark:border-grayteck-600 text-base font-medium rounded-md text-grayteck-700 dark:text-grayteck-300 bg-white dark:bg-darkbg-700 hover:bg-gray-50 dark:hover:bg-darkbg-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-500 transition-colors duration-200"
              >
                <Home className="w-5 h-5 mr-2" />
                Back to Home
              </motion.button>
            )}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default ErrorPage;