import { useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { loginUser, logoutUser, getCurrentUser, refreshAccessToken } from '../functions/auth_fethcers';
import { authStorage } from '../functions/auth';
import { useNavigate } from 'react-router-dom';


export const useAuth = (onLogoutComplete) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Login mutation
  const login = useMutation(
    async ({ username, password }) => {
      const response = await loginUser(username, password);
      return { ...response, username };
    },
    {
      onSuccess: (data) => {
        // Auth storage is handled in loginUser function
        
        // Store additional user info if needed
        const userData = {
          username: data.username,
          lastLogin: new Date().toISOString()
        };
        authStorage.setUserData(userData);

        // Invalidate and refetch user data
        queryClient.invalidateQueries('user');
        
        toast.success('Successfully logged in!');
        navigate('/');
      },
      onError: (error) => {
        console.error('Login error:', error);
        toast.error(error.detail || 'Failed to login. Please check your credentials.');
        authStorage.clear();
      }
    }
  );


// New refresh token mutation
const refresh = useMutation(
    async () => {
        const currentRefreshToken = authStorage.getRefreshToken();
        if (!currentRefreshToken) {
        throw new Error('No refresh token available');
        }
        const response = await refreshAccessToken(currentRefreshToken);
        return response;
    },
    {
        onSuccess: (data) => {
        const { access_token, refresh_token, expires_in } = data;
        const expiresAt = new Date(Date.now() + expires_in * 1000).toISOString();
        
        // Update storage with new tokens
        authStorage.set({
            accessToken: access_token,
            refreshToken: refresh_token,
            expiresAt
        });

        // Invalidate user query to trigger refetch with new token
        queryClient.invalidateQueries('user');
        
        toast.success('Token refreshed successfully');
        },
        onError: (error) => {
        console.error('Token refresh error:', error);
        toast.error('Failed to refresh token');
        
        // If refresh fails, log out the user
        authStorage.clear();
        queryClient.clear();
        navigate('/login');
        }
    }
    );


  // Logout mutation
  const logout = useMutation(
    () => logoutUser(),
    {
      onSuccess: () => {
        // Clear all cached queries
        queryClient.clear();
        authStorage.clear();

        toast.success('Successfully logged out');
        
        // Call the callback if provided
        if (onLogoutComplete) {
          onLogoutComplete();
        } else {
          navigate('/login');
        }
      },
      onError: (error) => {
        console.error('Logout error:', error);
        toast.error('Error during logout. Please try again.');
        // Still clear local data on error
        authStorage.clear();
        queryClient.clear();
        navigate('/login');
      }
    }
  );

  // Get current user query
  const { data: user, isLoading: isLoadingUser } = useQuery(
    'user',
    getCurrentUser,
    {
      enabled: !!authStorage.getAccessToken(),
      retry: false,
      staleTime: 300000, // 5 minutes
      onError: (error) => {
        console.error('User fetch error:', error);
        if (error.response?.status === 401) {
          authStorage.clear();
          navigate('/login');
        }
      }
    }
  );

  // Helper functions
  const isAuthenticated = useCallback(() => {
    const token = authStorage.getAccessToken();
    return !!token && !authStorage.isTokenExpired();
  }, []);

  const getUser = useCallback(() => {
    return authStorage.getUserData();
  }, []);

  const setUser = useCallback((userData) => {
    if (userData) {
      authStorage.setUserData(userData);
    } else {
      authStorage.clearUserData();
    }
  }, []);

  const getToken = useCallback(() => {
    return authStorage.getAccessToken();
  }, []);

  return {
    login,
    logout,
    refresh,
    user,
    isLoadingUser,
    isAuthenticated,
    getUser,
    setUser,
    getToken,
    isLoggingIn: login.isLoading,
    isLoggingOut: logout.isLoading,
    isRefreshing: refresh.isLoading,
    loginError: login.error,
    logoutError: logout.error,
    refreshError: refresh.error
  };
};