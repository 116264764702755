import React from 'react';
import { motion } from 'framer-motion';
import { Construction, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-darkbg-900 dark:to-darkbg-800 flex items-center justify-center px-4">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-lg w-full"
      >
        {/* Back Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate(-1)}
          className="mb-8 flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Go Back
        </motion.button>

        <div className="bg-white dark:bg-darkbg-700 rounded-lg shadow-xl p-8 text-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 200, damping: 20 }}
            className="relative inline-block mb-6"
          >
            <div className="absolute -inset-1 bg-grayteck-500 opacity-20 rounded-full blur" />
            <Construction className="relative w-16 h-16 text-grayteck-500" />
          </motion.div>
          
          <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
            Client Portal Coming Soon
          </h1>
          
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            We're building something amazing! Our client portal will provide easy access to all your documents and more.
          </p>
          
          <div className="bg-gray-50 dark:bg-darkbg-600 rounded-lg p-4 mb-6">
            <h2 className="font-semibold text-gray-900 dark:text-gray-100 mb-2">
              Coming Features:
            </h2>
            <ul className="text-left text-gray-600 dark:text-gray-300 space-y-2">
              <li className="flex items-center">
                <span className="w-2 h-2 bg-grayteck-500 rounded-full mr-2"></span>
                Document Management
              </li>
              <li className="flex items-center">
                <span className="w-2 h-2 bg-grayteck-500 rounded-full mr-2"></span>
                Secure Document Sharing
              </li>
              <li className="flex items-center">
                <span className="w-2 h-2 bg-grayteck-500 rounded-full mr-2"></span>
                Real-time Updates
              </li>
            </ul>
          </div>

          <p className="text-sm text-gray-500 dark:text-gray-400">
            We'll notify you when the portal is ready. Thank you for your patience!
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default ComingSoon;