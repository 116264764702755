import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, PenTool, Share2, UserCheck, Clock, Eye } from 'lucide-react';

const ProgressTracker = ({ document = {} }) => {
    const steps = [
        {
          id: 'created',
          title: 'Document Created',
          icon: FileText,
          description: 'Initial document setup',
          isCompleted: !!document,
          date: document?.created_at
        },
        {
          id: 'edited',
          title: 'Content Added',
          icon: PenTool,
          description: 'Document content prepared',
          isCompleted: document?.content && document.content.length > 0,
          isCurrent: document?.content && document.content.length > 0 && !document?.shareable_link,
          date: document?.updated_at
        },
        {
          id: 'published',
          title: 'Published',
          icon: Share2,
          description: 'Ready for signature',
          isCompleted: !!document?.shareable_link,
          isCurrent: !!document?.shareable_link && !document?.signed_by,
          date: document?.published_at,
        },
        {
            id: 'awaiting_signature',
            title: 'Awaiting Signature',
            icon: Clock,
            description: 'Shared with signer',
            isCompleted: document?.shareable_link && !document?.signed_by,
            isCurrent: document?.shareable_link && !document?.signed_by && document?.published,
            date: document?.published_at
          },
        {
            id: 'opened_first_time',
            title: 'Opened First Time',
            icon: Eye,
            description: 'Document opened by recipient',
            date: document?.first_opened_at,
          },
        
        {
          id: 'signed',
          title: 'Signed',
          icon: UserCheck,
          description: 'Document completed',
          isCompleted: !!document?.signed_by,
          date: document?.signed_at
        }
      ];
      
      // Update the currentStepIndex calculation:
      const getCurrentStepIndex = () => {
        if (document?.signed_by) return 5; // Signed
        if (document?.first_opened_at) return 4; // Opened First Time
        if (document?.shareable_link && !document?.first_opened_at) return 3; // Awaiting Signature
        if (document?.shareable_link) return 2; // Published
        if (document?.content && document.content.length > 0) return 1; // Content Added
        return 0; // Created
      };
      const currentStepIndex = getCurrentStepIndex();


      // Line progress calculation
  const progressPercentage =
  currentStepIndex >= 0
    ? (currentStepIndex / (steps.length - 1)) * 100
    : 0;

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const stepVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const iconVariants = {
    hidden: { scale: 0, rotate: -180 },
    visible: { 
      scale: 1, 
      rotate: 0,
      transition: { type: "spring", stiffness: 200, damping: 15 }
    },
    completed: {
      scale: [1, 1.2, 1],
      transition: { duration: 0.3 }
    }
  };

  const lineVariants = {
    hidden: { width: "0%" },
    visible: (custom) => ({
      width: `${(Math.max(0, currentStepIndex) / (steps.length - 1)) * 100}%`,
      transition: { 
        duration: 0.8,
        ease: "easeInOut",
        delay: custom * 0.2
      }
    })
  };

  const pulseVariants = {
    pulse: {
      scale: [1, 1.1, 1],
      opacity: [0.7, 1, 0.7],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <motion.div 
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="w-full bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-6 mb-8"
    >
      <div className="max-w-4xl mx-auto">
        <motion.h3 
          variants={stepVariants}
          className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-6 text-center"
        >
          Document Progress
        </motion.h3>
        
        <div className="relative">
          {/* Background Line */}
          <div className="absolute left-0 top-1/2 w-full h-0.5 bg-gray-200 dark:bg-gray-700 transform -translate-y-1/2" />
          
          {/* Animated Progress Line */}
          <motion.div
            style={{ width: `${progressPercentage}%` }}
            className="absolute left-0 top-1/2 h-0.5 bg-gradient-to-r from-blue-500 to-blue-400 transform -translate-y-1/2"
          />

          {/* Steps */}
          <div className="relative flex justify-between">
            {steps.map((step, index) => {
              const StepIcon = step.icon;
              const isCurrent = index === currentStepIndex;
              const isCompleted = index < currentStepIndex;

              return (
                <motion.div
                  key={step.id}
                  variants={stepVariants}
                  className="flex flex-col items-center relative"
                  style={{ width: '100px' }}
                >
                  {/* Step Circle with Icon */}
                  <motion.div
                    variants={iconVariants}
                    animate={isCompleted ? "completed" : "visible"}
                    className={`
                      relative w-10 h-10 rounded-full flex items-center justify-center
                      ${isCompleted || isCurrent
                        ? 'bg-gradient-to-r from-blue-500 to-blue-400'
                        : 'bg-gray-200 dark:bg-gray-700'}
                      ${isCurrent ? 'ring-4 ring-blue-100 dark:ring-blue-900' : ''}
                      transition-colors duration-200
                    `}
                  >
                    <StepIcon 
                      className={`w-5 h-5 ${
                        isCompleted || isCurrent
                          ? 'text-white'
                          : 'text-gray-400 dark:text-gray-500'
                      }`}
                    />
                    
                    {/* Pulse effect for current step */}
                    {isCurrent && (
                      <motion.div
                        variants={pulseVariants}
                        animate="pulse"
                        className="absolute inset-0 rounded-full bg-blue-500 dark:bg-blue-400 -z-10"
                      />
                    )}
                  </motion.div>

                  {/* Step Title */}
                  <AnimatePresence mode="wait">
                    <motion.p
                      key={`title-${step.isCompleted}`}
                      initial={{ opacity: 0, y: 5 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -5 }}
                      transition={{ duration: 0.2 }}
                      className={`mt-2 text-sm font-medium text-center
                        ${isCompleted || isCurrent
                          ? 'text-gray-900 dark:text-gray-100' 
                          : 'text-gray-500 dark:text-gray-400'}
                      `}
                    >
                      {step.title}
                    </motion.p>
                  </AnimatePresence>

                  {/* Step Description and Date */}
                  <motion.div
                    variants={stepVariants}
                    className="mt-1 text-center"
                  >
                    <p className="text-xs text-gray-500 dark:text-gray-400 max-w-[100px]">
                      {step.description}
                    </p>
                    {step.date && (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="block text-xs italic mt-1 text-gray-400 dark:text-gray-500"
                      >
                        {new Date(step.date).toLocaleString()}
                      </motion.span>
                    )}
                  </motion.div>

                  {/* Current Step Indicator */}
                  {isCurrent && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.3 }}
                      className="absolute -bottom-6 left-1/2 transform -translate-x-1/2"
                    >
                      <div className="w-2 h-2 bg-blue-500 dark:bg-blue-400 rounded-full" />
                    </motion.div>
                  )}
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProgressTracker;


