import { useQuery, useMutation, useQueryClient } from 'react-query';
import { 
  getAllDocuments, 
  getDocument, 
  getSharedDocument,
  createDocument, 
  updateDocument, 
  deleteDocument, 
  signDocument, 
  publishDocument,
  downloadDocumentAsPDF,
  unpublishDocument
} from '../functions/api_fethcers';
import { toast } from 'react-toastify';

export const useDocument = (id, token) => {

  const queryClient = useQueryClient();

  // Ensure we don't fetch both types simultaneously
  const isSharedDoc = Boolean(token);
  const documentId = isSharedDoc ? null : id;

  // Document fetch query
  const documentQuery = useQuery(
    ['document', id, token],
    async () => {

      if (token) {
        // For shared documents
        const response = await getSharedDocument(token);
        return {
          ...response.document,  // Spread the document metadata
          content: response.html_content,  // Store HTML content in content field
          isShared: true  // Flag to indicate this is a shared document
        };
      }
      if (id) {
        const result = await getDocument(id);
        return result;
      }
      return null;
    },
    {
      enabled: Boolean(id) || Boolean(token),
      staleTime: 0,
      // Ensure refetch on id change
      refetchOnMount: true,
      retry: 1,
      onError: (error) => {
        toast.error(error.message || 'Failed to fetch document');
      }
    }
  );

  // All documents query (only for regular document view)
  const allDocumentsQuery = useQuery('documents', getAllDocuments, {
    enabled: !isSharedDoc
  });

  // Create document mutation
  const createDocumentMutation = useMutation(createDocument, {
    onSuccess: (newDocument) => {

    // Immediately set the query data for the new document
    queryClient.setQueryData(['document', newDocument._id], newDocument);
    
    // Also update the documents list
    queryClient.setQueryData('documents', (old) => {
      return old ? [...old, newDocument] : [newDocument];
    });
    return newDocument; // Make sure to return the new document
    },
    onError: (error) => {
      toast.error(error.message || 'Failed to create document');
    }
  });

  // Update document mutation
  const updateDocumentMutation = useMutation(updateDocument, {
    onSuccess: () => {
      if (documentId) {
        queryClient.invalidateQueries(['document', documentId]);
      }
      queryClient.invalidateQueries('documents');
      // toast.success('Document updated successfully!');
    },
    onError: (error) => {
      toast.error(error.message || 'Failed to update document');
    }
  });

  // Delete document mutation
  const deleteDocumentMutation = useMutation(deleteDocument, {
    onMutate: async (deletedDocId) => {
      await queryClient.cancelQueries('documents');
  
      // Snapshot the previous value
      const previousDocuments = queryClient.getQueryData('documents');
  
      // Optimistically update to remove the document
      queryClient.setQueryData('documents', (old) =>
        old ? old.filter((doc) => doc._id !== deletedDocId) : []
      )
      return { previousDocuments };
  },
    onSuccess: () => {
      queryClient.invalidateQueries('documents');
      toast.success('Document deleted successfully!');
    },
    onError: (error, deletedDocId, context) => {
      // Rollback to the previous state if the mutation fails
      queryClient.setQueryData('documents', context.previousDocuments);
      toast.error(error.message || 'Failed to delete document');
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries('documents');
    },
  });

  // Download document mutation
  const downloadDocumentMutation = useMutation(
    // Accept optional id parameter in mutation function
    (mutationId = null) => {
      // Use passed-in ID or fall back to documentId from hook
      const idToUse = mutationId || documentId;
      
      if (!idToUse) {
        throw new Error('Cannot download document without ID');
      }
      return downloadDocumentAsPDF(idToUse);
    },
    {
      onError: (error) => {
        toast.error(error.message || 'Failed to download document');
      },
      onSuccess: () => {
        toast.success('Document downloaded successfully!');
      },
      retry: 0,
    }
  );

  // Publish document mutation
  const publishDocumentMutation = useMutation(
    () => {
      if (!documentId) {
        throw new Error('Cannot publish document without ID');
      }
      return publishDocument(documentId);
    },
    {
      onMutate: async () => {
        if (!documentId) return;

        await queryClient.cancelQueries(['document', documentId]);
        const previousDocument = queryClient.getQueryData(['document', documentId]);

        queryClient.setQueryData(['document', documentId], old => ({
          ...old,
          shareable_link: 'generating...',
          // link_expires_at: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) // 7 days from now
        }));

        return { previousDocument };
      },
      onError: (error, _variables, context) => {
        if (documentId) {
          queryClient.setQueryData(['document', documentId], context?.previousDocument);
        }
        toast.error(error.message || 'Failed to publish document');
      },
      onSuccess: (data) => {
        if (documentId) {
          queryClient.setQueryData(['document', documentId], old => ({
            ...old,
            ...data
          }));
        }
        queryClient.invalidateQueries(['document', documentId]);
        queryClient.invalidateQueries('documents');
        toast.success('Document published successfully!');
      }
    }
  );

  // Unpublish document mutation
  const unpublishDocumentMutation = useMutation(
    () => {
      if (!documentId) {
        throw new Error('Cannot unpublish document without ID');
      }
      return unpublishDocument(documentId);
    },
    {
      onMutate: async () => {
        if (!documentId) return;

        await queryClient.cancelQueries(['document', documentId]);
        const previousDocument = queryClient.getQueryData(['document', documentId]);

        queryClient.setQueryData(['document', documentId], old => ({
          ...old,
          shareable_link: null,
          first_opened_at: null,
          last_opened_at: null,
          open_count: null
        }));

        return { previousDocument };
      },
      onError: (error, _variables, context) => {
        if (documentId) {
          queryClient.setQueryData(['document', documentId], context?.previousDocument);
        }
        toast.error(error.message || 'Failed to unpublish document');
      },
      onSuccess: (data) => {
        if (documentId) {
          queryClient.setQueryData(['document', documentId], data);
        }
        queryClient.invalidateQueries(['document', documentId]);
        queryClient.invalidateQueries('documents');
        toast.success('Document unpublished successfully!');
      }
    }
  );

  // Sign document mutation
  const signDocumentMutation = useMutation(
    // Use stored token from hook context
    (signatureData) => signDocument(token, signatureData),
    {
      onMutate: async (signatureData) => {
        if (!token) return;

        await queryClient.cancelQueries(['document', null, token]);
        
        const previousDocument = queryClient.getQueryData(['document', null, token]);
        
        // Update the cached document with optimistic update
        queryClient.setQueryData(['document', null, token], (old) => ({
          ...old,
          signed_by: signatureData.signer_name,
          signature_data: signatureData.signature_img,
          signed_at: signatureData.signature_date
        }));
        
        return { previousDocument };
      },
      onError: (error, _variables, context) => {
        if (token) {
          queryClient.setQueryData(['document', null, token], context?.previousDocument);
        }
        
        const errorMessage = error.message || 'Failed to sign document';
        if (errorMessage.includes('already signed')) {
          toast.error('This document has already been signed');
        } else if (errorMessage.includes('expired')) {
          toast.error('This signing link has expired');
        } else {
          toast.error('Failed to sign document. Please try again.');
        }
      },
      onSuccess: (data) => {
        if (token) {
          // Update the cached document with the server response
          queryClient.setQueryData(['document', null, token], (old) => ({
            ...old,
            ...data,
            content: data.html_content // Ensure HTML content is properly updated
          }));
          toast.success('Document signed successfully!');
        }
      }
    }
  );

  return {
    // Document data
    document: documentQuery.data,
    isLoading: documentQuery.isLoading,
    isError: documentQuery.isError,
    error: documentQuery.error,
    
    // All documents (only for regular view)
    allDocuments: allDocumentsQuery.data,
    isLoadingAll: allDocumentsQuery.isLoading,
    isErrorAll: allDocumentsQuery.isError,
    
    // Mutations
    createDocument: createDocumentMutation.mutateAsync,
    updateDocument: updateDocumentMutation.mutateAsync,
    deleteDocument: deleteDocumentMutation.mutate,
    publishDocument: publishDocumentMutation.mutateAsync,
    signDocument: signDocumentMutation.mutateAsync,
    downloadDocument: downloadDocumentMutation.mutateAsync,
    unpublishDocument: unpublishDocumentMutation.mutateAsync,
    
    // Publishing states
    isPublishing: publishDocumentMutation.isLoading,
    publishError: publishDocumentMutation.error,
    isUnpublishing: unpublishDocumentMutation.isLoading,
    unpublishError: unpublishDocumentMutation.error,
    
    // Signing states
    isSigningDocument: signDocumentMutation.isLoading,
    signError: signDocumentMutation.error,

    // Download states
    isDownloading: downloadDocumentMutation.isLoading,
    downloadError: downloadDocumentMutation.error,
    
    // Helper flags
    isSharedDocument: isSharedDoc
  };
};