// src/utils/axiosConfig.js
import axios from 'axios';
import { authStorage } from './auth';
import { refreshAccessToken } from './auth_fethcers';

export const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Request interceptor
  instance.interceptors.request.use((config) => {
    // Don't add token for auth endpoints
    if (!config.url.includes('/auth/')) {
      const token = authStorage.getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Response interceptor
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      
      if (
        error.response?.status === 401 && 
        !originalRequest._retry &&
        !originalRequest.url.includes('/token') &&
        !originalRequest.url.includes('/refresh')
      ) {
        originalRequest._retry = true;
        
        try {
          const refreshToken = authStorage.getRefreshToken();
          if (!refreshToken) throw new Error('No refresh token');

          const response = await refreshAccessToken(refreshToken);
          const { access_token, refresh_token, expires_in } = response;
          
          // Update storage
          const expiresAt = new Date(Date.now() + expires_in * 1000).toISOString();
          authStorage.set({
            accessToken: access_token,
            refreshToken: refresh_token,
            expiresAt
          });

          originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
          return instance(originalRequest);
        } catch (refreshError) {
          authStorage.clear();
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};