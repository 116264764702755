import React from 'react';

const CapabilitiesStyles = () => {
  return (
    <style jsx global>{`
      body {
        background-color: #f1f5f9;
        margin: 0;
        min-height: 100vh;
        padding: 2rem;
      }

      .document-wrapper {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;
        padding-bottom: 8rem;
      }

      .document-container {
        background: white;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        margin-bottom: 4rem;

      }

      .document-content {
        --primary-color: #1a365d;
        --secondary-color: #718096;
        --accent-color: #4299e1;
        --text-color: #2d3748;
        --light-bg: #f7fafc;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        line-height: 1.6;
        color: var(--text-color);
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
      }

      .cover-page {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
        color: white;
        padding: 2rem;
        margin: -40px;
      }

      .cover-page h1 {
        font-size: 3rem;
        color: white !important;
        border: none !important;
        margin-bottom: 1rem;
      }

      .cover-page h2 {
        font-size: 2rem;
        color: white !important;
        margin-bottom: 2rem;
      }
        /*changes*/
      .section {
        margin: 3rem 0;
        padding: 2rem;
        page-break-inside: avoid;
      }

      .service-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
        margin: 2rem 0;
      }

      .service-card {
        background: var(--light-bg);
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      }

      .highlight-box {
        background: var(--light-bg);
        padding: 1.5rem;
        border-left: 4px solid var(--accent-color);
        margin: 1.5rem 0;
      }

      .tech-list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0;
      }

      .tech-item {
        background: var(--light-bg);
        padding: 0.5rem 1rem;
        border-radius: 4px;
        font-size: 0.9rem;
      }

      h1 {
        font-size: 2.5rem;
        color: var(--primary-color);
        margin-bottom: 1.5rem;
        border-bottom: 2px solid var(--accent-color);
        padding-bottom: 0.5rem;
      }

      h2 {
        font-size: 1.8rem;
        color: var(--primary-color);
        margin: 2rem 0 1rem;
      }

      h3 {
        font-size: 1.4rem;
        color: var(--secondary-color);
        margin: 1.5rem 0 1rem;
      }

      .dark .document-content {
        --primary-color: #60a5fa;
        --secondary-color: #93c5fd;
        --accent-color: #3b82f6;
        --text-color: #f3f4f6;
        --light-bg: #1f2937;
      }
        .company-footer {
        margin-top: 4rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 2rem 0;
        background: white;
        text-align: center;
        border-top: 1px solid var(--accent-color);
        }

        .company-footer .company-name {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--primary-color);
        }

        .company-footer .company-tagline {
        font-size: 1rem;
        color: var(--secondary-color);
        font-style: italic;
        }

        .dark .company-footer {
        background: var(--light-bg);
        }

        /* Table of Contents styles */
      .toc {
        margin: 2rem 0;
        padding: 2rem;
        background: var(--light-bg);
        border-radius: 8px;
      }

      .toc ol {
        list-style: none;
        padding: 0;
        counter-reset: toc-counter;
      }

      .toc li {
        margin: 0.75rem 0;
        padding-left: 2rem;
        position: relative;
        counter-increment: toc-counter;
      }

      .toc li::before {
        content: counter(toc-counter) ".";
        position: absolute;
        left: 0;
        color: var(--accent-color);
        font-weight: bold;
      }

      .toc a {
        color: var(--text-color);
        text-decoration: none;
        transition: color 0.2s;
      }

      .toc a:hover {
        color: var(--accent-color);
      }

      /* Core Values styles */
      .core-values {
        margin: 2rem 0;
      }

      .core-values ul {
        list-style: none;
        padding: 0;
      }

        /* Option 0: Inital Style */
      /*
      .core-values li {
        margin: 1rem 0;
        padding-left: 2.5rem;
        position: relative;
      }

      .core-values li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;
        background-color: var(--accent-color);
        opacity: 0.2;
        border-radius: 50%;
      }
        */


       /* Option 1: Clean dots with accent border */
      .core-values li {
        margin: 1.25rem 0;
        padding-left: 2rem;
        position: relative;
        display: flex;
        align-items: flex-start;
      }

      .core-values li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.5rem;
        width: 0.75rem;
        height: 0.75rem;
        background-color: var(--accent-color);
        border-radius: 50%;
        opacity: 0.8;
      }

      /* Option 2: Modern checkmark style */
      /*
      .core-values li {
        margin: 1.25rem 0;
        padding-left: 2rem;
        position: relative;
      }

      .core-values li::before {
        content: '✓';
        position: absolute;
        left: 0;
        top: 0;
        color: var(--accent-color);
        font-weight: bold;
        font-size: 1.1rem;
      }
      */

      /* Option 3: Hexagon style 
      /*
      .core-values li {
        margin: 1.25rem 0;
        padding-left: 2.5rem;
        position: relative;
      }

      .core-values li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.25rem;
        width: 1rem;
        height: 1rem;
        background-color: var(--accent-color);
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        opacity: 0.8;
      }
    

     /*
      .core-values li::after {
        content: '→';
        position: absolute;
        left: 0.5rem;
        color: var(--accent-color);
      }
        */

       .core-values li strong {
        color: var(--primary-color);
        margin-right: 0.5rem;
        font-weight: 600;
      }
      
      /* Dark mode adjustments */
      .dark .core-values li strong {
        color: var(--accent-color);
      }
      /* Section headings with anchors */
      .section {
        scroll-margin-top: 2rem;
      }

      .section h1 {
        position: relative;
      }

      .section h1 .anchor {
        position: absolute;
        top: 0;
        left: -1rem;
        opacity: 0;
        transition: opacity 0.2s;
        color: var(--accent-color);
        text-decoration: none;
      }

      .section h1:hover .anchor {
        opacity: 1;
      }


      /* Specialized Capabilities styles */
.specialized-capabilities {
  margin: 2rem 0;
}

.specialized-capabilities ul {
  list-style: none;
  padding: 0;
}

.specialized-capabilities li {
  margin: 1.25rem 0;
  padding-left: 2rem;
  position: relative;
  display: flex;
  align-items: flex-start;
}

.specialized-capabilities li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

.specialized-capabilities li strong {
  color: var(--primary-color);
  margin-right: 0.5rem;
  font-weight: 600;
}

/* Dark mode adjustments */
.dark .specialized-capabilities li strong {
  color: var(--accent-color);
}

/* Enhance tech-list items */
.tech-item {
  background: var(--light-bg);
  padding: 0.75rem 1.25rem;
  border-radius: 6px;
  font-size: 0.95rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.tech-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Dark mode adjustments for tech items */
.dark .tech-item {
  border-color: rgba(255,255,255,0.1);
}

/* Add these styles to your CapabilitiesStyles component, enhancing the existing service-grid and service-card styles */

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.service-card {
  background: var(--light-bg);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  overflow: hidden;
}

.service-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--accent-color);
  opacity: 0;
  transition: opacity 0.2s;
}

.service-card:hover::before {
  opacity: 1;
}

.service-card h3 {
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.service-card p {
  color: var(--text-color);
  margin: 0;
  font-size: 0.95rem;
}

/* Dark mode adjustments */
.dark .service-card {
  border-color: rgba(255,255,255,0.1);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.dark .service-card:hover {
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}

.process-steps {
  position: relative;
  padding-left: 3rem;
  margin: 2rem 0;
}

/* Timeline line */
.process-steps::before {
  content: '';
  position: absolute;
  left: 1rem;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--accent-color);
  opacity: 0.3;
}

.process-step {
  background: var(--light-bg);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  margin-bottom: 2rem;
}

/* Timeline dot */
.process-step::before {
  content: '';
  position: absolute;
  left: -3rem;
  top: 1.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--accent-color);
  border: 3px solid var(--light-bg);
  z-index: 1;
}

/* Connector line */
.process-step::after {
  content: '';
  position: absolute;
  left: -2rem;
  top: 2rem;
  width: 2rem;
  height: 2px;
  background: var(--accent-color);
  opacity: 0.3;
}

.process-step:hover {
  transform: translateX(4px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.process-step:hover::before {
  transform: scale(1.2);
  box-shadow: 0 0 10px var(--accent-color);
}

.process-step h4 {
  color: var(--primary-color);
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.process-step h4::before {
  content: attr(data-number);
  background: var(--accent-color);
  color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  font-size: 1rem;
  flex-shrink: 0;
}

.process-step p {
  color: var(--text-color);
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.6;
}

/* Dark mode adjustments */
.dark .process-step {
  border-color: rgba(255,255,255,0.1);
}

.dark .process-step::before {
  border-color: var(--light-bg);
}

.dark .process-step:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
}

/* Security Standards box enhancement */
.security-box {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  border-left: 4px solid var(--accent-color);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 2rem 0;
}

.security-box h3 {
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
}

.security-standards ul,
.compliance-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.security-standards li {
  margin: 1rem 0;
  padding-left: 2.5rem;
  position: relative;
}

.security-standards li::before {
  content: '🔒';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
}

/* Compliance Capabilities styling */
.compliance-list li {
  margin: 1rem 0;
  padding-left: 2.5rem;
  position: relative;
  display: flex;
  align-items: center;
}

.compliance-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--accent-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

/* Add hover effects */
.security-standards li,
.compliance-list li {
  transition: transform 0.2s;
}

.security-standards li:hover,
.compliance-list li:hover {
  transform: translateX(4px);
}

/* Dark mode adjustments */
.dark .security-box {
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}


.onboarding-process {
    margin: 2rem 0;
    position: relative;
    padding-left: 2rem;
  }
  
  .onboarding-process::before {
    content: '';
    position: absolute;
    left: 0.85rem;
    top: 0;
    bottom: 0;
    width: 2px;
    background: var(--accent-color);
    opacity: 0.2;
  }
  
  .onboarding-process ol {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: onboarding-counter;
  }
  
  .onboarding-process li {
    counter-increment: onboarding-counter;
    padding: 0.75rem 0 0.75rem 2rem;
    margin: 0.5rem 0;
    position: relative;
    transition: transform 0.2s;
    color: var(--text-color);
    font-size: 0.95rem;
  }
  
  .onboarding-process li::before {
    content: counter(onboarding-counter);
    position: absolute;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    background: var(--light-bg);
    border: 2px solid var(--accent-color);
    color: var(--accent-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    transition: all 0.2s;
  }
  
  .onboarding-process li:hover {
    transform: translateX(4px);
  }
  
  .onboarding-process li:hover::before {
    background: var(--accent-color);
    color: white;
    box-shadow: 0 0 10px var(--accent-color);
  }
  
  /* Dark mode adjustments */
  .dark .onboarding-process li::before {
    background: var(--darkbg-700);
  }
  
  .dark .onboarding-process li:hover::before {
    background: var(--accent-color);
  }

/* Engagement Models styles */
.engagement-models {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.engagement-card {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.engagement-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--accent-color);
  opacity: 0;
  transition: opacity 0.2s;
}

.engagement-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.engagement-card:hover::before {
  opacity: 1;
}

.engagement-card h4 {
  margin: 0 0 1rem 0;
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 600;
}

.engagement-card p {
  margin: 0;
  color: var(--text-color);
  font-size: 0.95rem;
  line-height: 1.6;
}

.dark .engagement-card {
  border-color: rgba(255,255,255,0.1);
}

/* Project Cards styles */
.performance-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.project-card {
  background: var(--light-bg);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0,0,0,0.1);
}

.project-card::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3rem 3rem 0;
  border-color: transparent var(--accent-color) transparent transparent;
  opacity: 0.1;
  transition: opacity 0.2s;
}

.project-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.project-card:hover::after {
  opacity: 0.2;
}

.project-card h3 {
  color: var(--primary-color);
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.project-card p {
  color: var(--text-color);
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.6;
}

/* Industries Served styles */
.industries-list {
  margin: 2rem 0;
}

.industries-list ul {
  list-style: none;
  padding: 0;
}

.industries-list li {
  margin: 1.25rem 0;
  padding-left: 2rem;
  position: relative;
  display: flex;
  align-items: flex-start;
}

.industries-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

.industries-list li strong {
  color: var(--primary-color);
  margin-right: 0.5rem;
  font-weight: 600;
}

/* Dark mode adjustments */
.dark .industries-list li strong {
  color: var(--accent-color);
}

/* Dark mode adjustments */
/*
.dark .project-card,
.dark .industries-list li {
  border-color: rgba(255,255,255,0.1);
}

.dark .project-card:hover {
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}

.dark .industries-list li:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}
*/

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.expertise-card {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
}

.expertise-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--accent-color);
  opacity: 0;
  transition: opacity 0.2s;
}

.expertise-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.expertise-card:hover::before {
  opacity: 1;
}

.expertise-card h3 {
  color: var(--primary-color);
  margin: 0 0 1.5rem 0;
  font-size: 1.3rem;
  font-weight: 600;
}

.expertise-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.expertise-card li {
  margin: 1rem 0;
  padding-left: 1.5rem;
  position: relative;
  line-height: 1.5;
}

.expertise-card li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

.expertise-card strong {
  color: var(--primary-color);
  font-weight: 600;
  margin-right: 0.5rem;
}

.expertise-card p {
  margin: 0;
  line-height: 1.6;
  color: var(--text-color);
}

/* Domain Expertise tech list enhancements */
.domain-expertise .tech-list {
  margin-top: 1.5rem;
}

.domain-expertise .tech-item {
  background: var(--light-bg);
  padding: 0.75rem 1.25rem;
  border-radius: 6px;
  font-size: 0.95rem;
  border: 1px solid rgba(0,0,0,0.1);
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
}

.domain-expertise .tech-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}



/* Dark mode adjustments */
.dark .expertise-card {
  border-color: rgba(255,255,255,0.1);
}

.dark .expertise-card strong {
  color: var(--accent-color);
}

.dark .domain-expertise .tech-item {
  border-color: rgba(255,255,255,0.1);
}

.advantages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.advantage-card {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.advantage-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.08);
}

.advantage-card h3 {
  color: var(--primary-color);
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.advantage-card p {
  margin: 0;
  color: var(--text-color);
  line-height: 1.6;
}

.advantage-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.advantage-card li {
  margin: 0.75rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.advantage-card li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

.value-propositions {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  border-left: 4px solid var(--accent-color);
  margin: 2rem 0;
}

.value-propositions h3 {
  color: var(--primary-color);
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.value-propositions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.value-propositions li {
  margin: 1rem 0;
  padding-left: 2rem;
  position: relative;
  display: flex;
  align-items: center;
}

.value-propositions li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

/* Dark mode adjustments */
.dark .advantage-card,
.dark .value-propositions {
  border-color: rgba(255,255,255,0.1);
}

/* Quality & Standards Styles */
.quality-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.quality-card {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.quality-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.08);
}

.quality-card h3 {
  color: var(--primary-color);
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.quality-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.quality-card li {
  margin: 0.75rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.quality-card li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

.documentation-standards {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  border-left: 4px solid var(--accent-color);
  margin: 2rem 0;
}

.documentation-standards h3 {
  color: var(--primary-color);
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.documentation-standards ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.documentation-standards li {
  margin: 1rem 0;
  padding-left: 2rem;
  position: relative;
}

.documentation-standards li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

/* Dark mode adjustments */
.dark .quality-card {
  border-color: rgba(255,255,255,0.1);
}

/* Company Information Styles */
.company-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.company-info-box {
  background: var(--light-bg);
  padding: 2rem;
  border-radius: 8px;
  border-left: 4px solid var(--accent-color);
  margin-bottom: 2rem;
}

.company-info-box h3 {
  color: var(--primary-color);
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.company-info-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.company-info-box li {
  margin: 1rem 0;
  padding-left: 2rem;
  position: relative;
  line-height: 1.6;
}

.company-info-box li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

.company-info-box p {
  margin: 1rem 0;
  line-height: 1.6;
}

.company-info-box strong {
  color: var(--primary-color);
  font-weight: 600;
}

/* Dark mode adjustments */
.dark .company-info-box strong {
  color: var(--accent-color);
}

/* Contact Information Styles */
.contact-section {
  margin: 2rem 0;
}

.contact-box {
  background: var(--light-bg);
  padding: 2.5rem;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-box h3 {
  color: var(--primary-color);
  margin: 0 0 2rem 0;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.contact-box p {
  margin: 1.25rem 0;
  line-height: 1.6;
}

.contact-box strong {
  color: var(--primary-color);
  font-weight: 600;
  margin-right: 0.5rem;
}

.contact-box ul {
  list-style: none;
  padding: 0;
  margin: 1.25rem 0;
}

.contact-box li {
  margin: 1rem 0;
  padding-left: 2rem;
  position: relative;
}

.contact-box li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--accent-color);
  border-radius: 50%;
  opacity: 0.8;
}

/* Dark mode adjustments */
.dark .contact-box {
  border-color: rgba(255,255,255,0.1);
}

.dark .contact-box strong {
  color: var(--accent-color);
}

.dark .contact-box strong {
  color: var(--accent-color);
}

/* Signature Container Styles */
.signature-container {
  margin-top: 3rem;
  padding: 2rem;
  background: var(--light-bg);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.signature-container.signed {
  background: var(--light-bg);
  border-left: 4px solid var(--accent-color);
}

.signature-container .input-group {
  margin-bottom: 1.5rem;
}

.signature-container label {
  display: block;
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.signature-container .poc-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 1rem;
  background: white;
  color: var(--text-color);
  transition: border-color 0.2s, box-shadow 0.2s;
}

.signature-container .poc-input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
}

.signature-container .poc-input:disabled {
  background-color: var(--light-bg);
  cursor: not-allowed;
  border-color: rgba(0, 0, 0, 0.1);
}

#signature-pad {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 100%;
  height: 200px;
  background: white;
  margin: 1rem 0;
  cursor: crosshair;
}

.signature-container .button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.signature-container .action-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  background: var(--accent-color);
  color: white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.signature-container .action-button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.signature-container .action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: var(--secondary-color);
}

.signature-container .action-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.4);
}

.signature-container .action-button.clear {
  background: var(--light-bg);
  color: var(--text-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.signature-container .action-button.sign {
  background: var(--accent-color);
}

.signature-container .action-button.download {
  background: var(--primary-color);
}

.signature-image-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 1rem;
  background: white;
  margin: 1rem 0;
}

.signature-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.signature-date {
  margin-top: 1rem;
  color: var(--text-color);
  font-size: 0.95rem;
  text-align: right;
  font-style: italic;
}

/* Dark mode adjustments */
.dark .signature-container {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .signature-container .poc-input {
  background: var(--darkbg-700);
  border-color: rgba(255, 255, 255, 0.1);
  color: var(--light-bg);
}

.dark .signature-container .poc-input:disabled {
  background: var(--darkbg-800);
}

.dark #signature-pad {
  background: var(--darkbg-700);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .signature-container .action-button.clear {
  background: var(--darkbg-700);
  border-color: rgba(255, 255, 255, 0.1);
  color: var(--light-bg);
}

.dark .signature-image-container {
  background: var(--darkbg-700);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .signature-container label {
  color: var(--light-bg);
}
    `}</style>
  );
};

export default CapabilitiesStyles;