import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { ThemeProvider, ThemeContext } from './context/ThemeContext';
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid';
// import gt_logo from './imgs/logo_transparent_background_edited (2).png'
import gt_logo from './imgs/logo_transparent_background_edit.png'
import Login from './components/Login';
import Register from './components/Register';
import HomeScreen from './components/HomeScreen';
import DocumentEditor from './components/DocumentEditor';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicDocumentView from './components/PublicDocViewer';
import SignedDocumentPreview from './components/SignedDocumentPreview';
import ComingSoon from './components/ComingSoon';
import { useAuth } from './hooks/useAuth';
import ErrorPage from './pages/ErrorPage';
import TokenRefreshTest from './pages/TokenTestPage';

const Logo = () => (
  <div className="flex items-center">
    <img src={gt_logo} alt="GrayTeck Logo" className="h-8 w-auto mr-2" />
    <span className="font-bold text-xl">GrayTeck</span>
  </div>
);

const ThemeToggle = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <button
      onClick={toggleDarkMode}
      className="p-2 rounded-full bg-grayteck-200 dark:bg-darkbg-600"
    >
      {darkMode ? (
        <SunIcon className="h-6 w-6 text-yellow-500" />
      ) : (
        <MoonIcon className="h-6 w-6 text-grayteck-800" />
      )}
    </button>
  );
};

// Update PrivateRoute component within App.js
const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoadingUser } = useAuth();
  const location = useLocation();

  // Debug logging
  console.log('PrivateRoute Check:', {
    isLoadingUser,
    isAuthenticated: isAuthenticated(), // Call the function
    token: localStorage.getItem('access_token'),
    location: location.pathname
  });

  // Show loading state while checking authentication
  if (isLoadingUser) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-grayteck-100 dark:bg-darkbg-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-grayteck-600"></div>
      </div>
    );
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated()) { // Call the function here
    console.log('Not authenticated, redirecting to login');
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Render protected content if authenticated
  console.log('Authenticated, rendering children');
  return children;
};

const AppContent = ({ user, onLogout }) => {
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);
  const { logout: logoutMutation } = useAuth(() => navigate('/login'));


  const handleLogout = async () => {
    try {
      await logoutMutation.mutateAsync();
    } catch (error) {
      console.error('Logout failed:', error);
      // Still navigate to login
      navigate('/login');
    }
  };


  useEffect(() => {
    const handleUnauthorized = () => {
      onLogout();
      navigate('/login');
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, [navigate, onLogout]);

  

  return (
    <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-darkbg-900 text-grayteck-900 dark:text-grayteck-100 min-h-screen">
        <header className="bg-grayteck-100 dark:bg-darkbg-800 p-4 flex justify-between items-center">
          <Logo />
          <div className="flex items-center">
            <span className="mr-4">Welcome, {user?.username}!</span>
            <button 
              onClick={handleLogout} 
              disabled={logoutMutation.isLoading}
              className="mr-4 text-grayteck-600 hover:text-grayteck-800 dark:text-grayteck-400 dark:hover:text-grayteck-200"
            >
              Logout
            </button>
            <ThemeToggle />
          </div>
        </header>
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

function App() {
  const navigate = useNavigate();
  
  const handleLogoutComplete = useCallback(() => {
    navigate('/login', { replace: true });
  }, [navigate]);
  
  const { logout, getUser } = useAuth(handleLogoutComplete);
  const user = getUser();




  return (
    <ThemeProvider>
        <ToastContainer />
        <Routes>
          {/* Public routes */}
          {/* <Route path="/token/test" element={<TokenRefreshTest />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/share/:token" element={<PublicDocumentView />} />
          <Route path="/client-portal" element={<ComingSoon />} />
          
          {/* Error routes */}
          <Route path="/error" element={<ErrorPage />} />
          
          {/* Private routes - Change from /* to specific routes */}
          <Route element={<PrivateRoute><AppContent user={user} onLogout={logout} /></PrivateRoute>}>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/create" element={<DocumentEditor />} />
            <Route path="/edit/:id" element={<DocumentEditor />} />
            <Route path="/view/:id" element={<SignedDocumentPreview />} />
          </Route>

          {/* 404 route - should be last */}
          <Route path="*" element={<ErrorPage 
            title="Page Not Found" 
            message="The page you're looking for doesn't exist." 
            errorCode="404"
            showHomeButton={false} 
            showBackButton={false} 
          />} />
        </Routes>
    </ThemeProvider>
  );
}

export default App;